import { Spinner } from 'reactstrap';

interface LoadingSpinnerProps {
    visible: boolean;
}

const LoadingSpinner = ({
    visible
}: LoadingSpinnerProps) => (
    <>
        {visible && (
            <div style={{
                position: 'fixed',
                backgroundColor: 'rgba(0,0,0,0.5)',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                zIndex: 1100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Spinner color="light" style={{
                    width: '5rem',
                    height: '5rem'
                }} />
            </div>
        )}
    </>
);

export default LoadingSpinner;
