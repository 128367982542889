import { useEffect, useState } from "react";
import { FaCartPlus, FaCopy, FaPrint, FaReceipt } from 'react-icons/fa';
import { connect, ConnectedProps } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Input, Label, Form, FormGroup, Row, CustomInput } from 'reactstrap';
import { ThunkDispatch } from "redux-thunk";
import { useToastMessage } from "../../hooks/use-toast-message";
import { currentLocalDate, invalidDate, localDateIsoString, normalizeDate, salesSortFunction } from "../../services/dates";
import { addLocalItem, clientsLocalKey, businessUnitsLocalKey, getLocalItems, getLocalItemsOrEmpty, paymentMethodsLocalKey, pendingSalesLocalKey, productsLocalKey, replaceLocalItem, setLocalItems } from "../../services/locals";
import { updateProductBarcode } from "../../services/products";
import { getSale, saveSale, saveQuotation, cancelSale } from '../../services/sales'
import { ApplicationState } from "../../store";
import { loadProducts, loadProductsSuccess, ProductsActions } from "../../store/products/actions";
import { loadClients, loadClientsSuccess, loadBusinessUnits, loadBusinessUnitsSuccess, loadPaymentMethods, loadPaymentMethodsSuccess, SalesActions } from "../../store/sales/actions";
import { StockProduct } from "../../models/products/stock-product";
import { Client } from "../../models/sales/client";
import { BusinessUnit } from "../../models/sales/business-unit";
import { PaymentMethod } from "../../models/sales/payment-method";
import { Sale } from "../../models/sales/sale";
import { SalePaymentMethod } from "../../models/sales/sale-payment-method";
import ProductSelector from "../common/ProductSelector";
import BarcodeUpdater from "../common/BarcodeUpdater";
import SelectedProduct from "./SelectedProduct";
import SaleItemList from "./SaleItemList";
import SalePaymentMethodList from "./SalePaymentMethodList";
import SaleCancelButton from "./SaleCancelButton"
import SaleReceipt from "./SaleReceipt";

const initialSalePaymentMethod: SalePaymentMethod = {
    id: 0,
    paymentMethodId: 0,
    paymentAmount: 0,
    receivedAmount: 0,
    paymentMethodName: ''
};

const initialSale: Sale = {
    id: 0,
    clientId: 1,
    businessUnitId: 1,
    date: currentLocalDate(),
    quotation: false,
    canceled: false,
    remarks: '',
    saleItems: [],
    salePaymentMethods: [{ ...initialSalePaymentMethod }],
    clientName: '',
    clientAddressLink: '',
    businessUnitDescription: ''
};

const connector = connect(
    (state: ApplicationState) => ({ ...state.products, ...state.sales }),
    (dispatch: ThunkDispatch<ApplicationState, void, ProductsActions | SalesActions>) => ({
        loadProducts: () => dispatch(loadProducts()),
        loadProductsSuccess: (products: StockProduct[]) => dispatch(loadProductsSuccess(products)),
        loadClients: () => dispatch(loadClients()),
        loadClientsSuccess: (clients: Client[]) => dispatch(loadClientsSuccess(clients)),
        loadBusinessUnits: () => dispatch(loadBusinessUnits()),
        loadBusinessUnitsSuccess: (businessUnits: BusinessUnit[]) => dispatch(loadBusinessUnitsSuccess(businessUnits)),
        loadPaymentMethods: () => dispatch(loadPaymentMethods()),
        loadPaymentMethodsSuccess: (paymentMethods: PaymentMethod[]) => dispatch(loadPaymentMethodsSuccess(paymentMethods)),
    })
);

const SalePage = ({
    products,
    clients,
    businessUnits,
    paymentMethods,
    recentSales,
    loadProducts,
    loadProductsSuccess,
    loadClients,
    loadClientsSuccess,
    loadBusinessUnits,
    loadBusinessUnitsSuccess,
    loadPaymentMethods,
    loadPaymentMethodsSuccess
}: ConnectedProps<typeof connector>) => {
    const cashPaymentMethodId = 1;

    const [sale, setSale] = useState<Sale>({
        ...initialSale,
        saleItems: [...initialSale.saleItems],
        salePaymentMethods: [...initialSale.salePaymentMethods]
    });

    const [selectedProducts, setSelectedProducts] = useState<StockProduct[]>();
    const [notFoundBarcode, setNotFoundBarcode] = useState<string>();
    const [activeSaleItemIndex, setActiveSaleItemIndex] = useState<number>();
    const [customDate, setCustomDate] = useState<boolean>(false);
    const [saleTotal, setSaleTotal] = useState<number>(0);
    const [validForm, setValidForm] = useState<boolean>(false);
    const [printSale, setPrintSale] = useState<Sale>();

    const { id } = useParams<{ id: string }>();

    const { showSuccessMessage, showWarningMessage, showErrorMessage } = useToastMessage();

    useEffect(() => {
        if (products.length === 0) {
            loadProducts()
                .then(value => setLocalItems(productsLocalKey, value.products))
                .catch(() => {
                    const products = getLocalItems<StockProduct>(productsLocalKey);
                    if (products) {
                        loadProductsSuccess(products);
                        showWarningMessage('Se cargó la versión anterior de los productos');
                    } else {
                        showErrorMessage('Error al cargar los productos');
                    }
                });
        }

        if (clients.length === 0) {
            loadClients()
                .then(value => setLocalItems(clientsLocalKey, value.clients))
                .catch(() => {
                    const clients = getLocalItems<Client>(clientsLocalKey);
                    if (clients) {
                        loadClientsSuccess(clients);
                        showWarningMessage('Se cargó la versión anterior de los clientes');
                    } else {
                        showErrorMessage('Error al cargar los clientes');
                    }
                });
        }

        if (businessUnits.length === 0) {
            loadBusinessUnits()
                .then(value => setLocalItems(businessUnitsLocalKey, value.businessUnits))
                .catch(() => {
                    const businessUnits = getLocalItems<BusinessUnit>(businessUnitsLocalKey);
                    if (businessUnits) {
                        loadBusinessUnitsSuccess(businessUnits);
                        showWarningMessage('Se cargó la versión anterior de las unidades de negocio');
                    } else {
                        showErrorMessage('Error al cargar las unidades de negocio');
                    }
                });
        }

        if (paymentMethods.length === 0) {
            loadPaymentMethods()
                .then(value => setLocalItems(paymentMethodsLocalKey, value.paymentMethods))
                .catch(() => {
                    const paymentMethods = getLocalItems<PaymentMethod>(paymentMethodsLocalKey);
                    if (paymentMethods) {
                        loadPaymentMethodsSuccess(paymentMethods);
                        showWarningMessage('Se cargó la versión anterior de las formas de pago');
                    } else {
                        showErrorMessage('Error al cargar las formas de pago');
                    }
                });
        }
    }, []);

    useEffect(() => {
        if (!selectedProducts) {
            (document.activeElement as HTMLElement)?.blur?.();
        }
    }, [selectedProducts]);

    useEffect(() => {
        if (sale.saleItems.length) {
            const newSaleTotal = sale.saleItems.reduce((st, si) => st + (si.salePrice - si.saleDiscount) * si.quantity, 0);
            const currentSaleTotal = sale.salePaymentMethods.reduce((st, spm) => st + spm.paymentAmount, 0);

            let remainingIncrement = newSaleTotal - currentSaleTotal;
            let currentIndex = sale.salePaymentMethods.length - 1;
            while (remainingIncrement && currentIndex >= 0) {
                handleUpdateSalePaymentMethod(currentIndex, 'paymentAmount', Math.max(sale.salePaymentMethods[currentIndex].paymentAmount + remainingIncrement, 0));
                remainingIncrement = Math.min(sale.salePaymentMethods[currentIndex].paymentAmount + remainingIncrement, 0);
                currentIndex--;
            }

            setSaleTotal(newSaleTotal);
        } else {
            setSaleTotal(0);
            setSale(sale => ({ ...sale, salePaymentMethods: [{ ...initialSalePaymentMethod }] }))
        }
    }, [sale.saleItems]);

    useEffect(() => {
        if (sale.id === 0) {
            var client = clients.find(c => c.id === sale.clientId);
            var businessUnit = businessUnits.find(bu => bu.id === client?.businessUnitId);
            businessUnit && setSale(sale => ({ ...sale, businessUnitId: businessUnit?.id ?? 1 }));
        }
    }, [sale.clientId]);

    useEffect(() => {
        if (!customDate && sale.id === 0) {
            setSale(sale => ({ ...sale, date: currentLocalDate() }));
            const intervalId = setInterval(() => setSale(sale => ({ ...sale, date: currentLocalDate() })), 1000);
            return () => clearInterval(intervalId);
        }
    }, [customDate, sale.id]);

    useEffect(() => {
        if (customDate && invalidDate(sale.date)) {
            setValidForm(false);
            return;
        }
        if (sale.saleItems.some(si => si.saleItemComponents.length && si.saleItemComponents.reduce((un, sic) => un + sic.unitsNumber, 0) < si.measureUnitsNumber * si.quantity)) {
            setValidForm(false);
            return;
        }
        if (saleTotal && !sale.quotation) {
            if (sale.salePaymentMethods.some(spm => spm.paymentMethodId === 0)) {
                setValidForm(false);
                return;
            }
            if (sale.salePaymentMethods.some(spm => spm.paymentAmount === 0)) {
                setValidForm(false);
                return;
            }
            const cashSalePaymentMethod = sale.salePaymentMethods.find(spm => spm.paymentMethodId === cashPaymentMethodId)
            if (cashSalePaymentMethod && cashSalePaymentMethod.receivedAmount < cashSalePaymentMethod.paymentAmount) {
                setValidForm(false);
                return;
            }
        }
        setValidForm(true);
    }, [customDate, saleTotal, sale.date, sale.quotation, sale.saleItems, sale.salePaymentMethods]);

    useEffect(() => {
        if (+id) {
            const sale = getLocalItemsOrEmpty<Sale>(pendingSalesLocalKey).find(s => s.id === +id) ?? recentSales.find(s => s.id === +id);
            if (sale) {
                resetState(sale);
            } else {
                getSale(+id)
                    .then(sale => {
                        resetState(sale);
                        !sale && showErrorMessage('No se encontró la venta');
                    })
                    .catch(() => {
                        resetState();
                        showErrorMessage('Error al cargar la venta');
                    });
            }
        } else {
            resetState();
        }
    }, [id]);

    const handleProductSelected = (id: number) => {
        const product = id ? products.find(p => p.id === id) : undefined;
        setSelectedProducts(getSelectedProducts(product));
    };

    const handleBarcodeScanned = (barcode: string) => {
        const product = barcode ? products.find(p => p.barCode === barcode) : undefined;
        setSelectedProducts(getSelectedProducts(product));
        setNotFoundBarcode(product ? undefined : barcode);
    };

    const handleUpdateProductBarcode = (productId: number, barcode: string) => {
        updateProductBarcode(productId, barcode)
            .then(() => {
                setNotFoundBarcode(undefined);
                showSuccessMessage('Código de barras actualizado correctamente');
            })
            .catch(() => showErrorMessage('Error al actualizar el código de barras'));
    };

    const handleClearBarcode = () => {
        setNotFoundBarcode(undefined);
    };

    const handleAddSelectedProduct = (product?: StockProduct, component?: StockProduct) => {
        if (product) {
            setSale(sale => ({
                ...sale,
                saleItems: [...sale.saleItems, {
                    id: 0,
                    productId: product.productId,
                    productTypeId: product.productTypeId,
                    measureId: product.measureId,
                    salePrice: roundAwayFromZero(product.salePrice),
                    saleDiscount: roundToZero(product.saleDiscount),
                    quantity: 1,
                    saleItemComponents: component ? [{
                        id: 0,
                        productId: component.productId,
                        productTypeId: component.productTypeId,
                        measureId: component.measureId,
                        unitsNumber: roundToFraction(Math.min(component.measureUnitsNumber, product.measureUnitsNumber)),
                        started: false,
                        finished: false,
                        fullName: component.fullName,
                        unitAbbreviation: component.measureUnitAbbreviation,
                        maxUnitsNumber: component.measureUnitsNumber
                    }] : [],
                    fullName: product.fullName,
                    measureUnitsNumber: product.measureUnitsNumber
                }]
            }));
        } else if (component && activeSaleItemIndex !== undefined && activeSaleItemIndex >= 0) {
            const activeSaleItem = sale.saleItems[activeSaleItemIndex];
            updateSaleItem(activeSaleItemIndex, 'saleItemComponents', [
                ...activeSaleItem.saleItemComponents, {
                    id: 0,
                    productId: component.productId,
                    productTypeId: component.productTypeId,
                    measureId: component.measureId,
                    unitsNumber: roundToFraction(Math.min(component.measureUnitsNumber, activeSaleItem.measureUnitsNumber * activeSaleItem.quantity - activeSaleItem.saleItemComponents.reduce((un, sic) => un + sic.unitsNumber, 0))),
                    started: false,
                    finished: false,
                    fullName: component.fullName,
                    unitAbbreviation: component.measureUnitAbbreviation,
                    maxUnitsNumber: component.measureUnitsNumber
                }
            ]);
        }

        setSelectedProducts(undefined);
    };

    const handleClearSelectedProduct = () => {
        setSelectedProducts(undefined);
    };

    const updateSale = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSale(sale => ({
            ...sale,
            [name]: value
        }));
    };

    const updateSaleItem = (index: number, name: string, value: any) => {
        setSale(sale => ({
            ...sale,
            saleItems: sale.saleItems.map((si, i) => i !== index ? si : {
                ...si,
                [name]: value
            })
        }));
    };

    const removeSaleItem = (index: number) => {
        setSale(sale => ({
            ...sale,
            saleItems: sale.saleItems.filter((_, i) => i !== index)
        }));
    };

    const handleAddSalePaymentMethod = () => {
        setSale(sale => ({
            ...sale,
            salePaymentMethods: [...sale.salePaymentMethods, { ...initialSalePaymentMethod }]
        }));
    };

    const handleUpdateSalePaymentMethod = (index: number, name: string, value: any) => {
        setSale(sale => ({
            ...sale,
            salePaymentMethods: sale.salePaymentMethods.map((spm, i) => i !== index ? spm : {
                ...spm,
                [name]: value
            })
        }));
    };

    const handleRemoveSalePaymentMethod = (index: number) => {
        setSale(sale => ({
            ...sale,
            salePaymentMethods: sale.salePaymentMethods.filter((_, i) => i !== index)
        }));
    };

    const handleSave = () => {
        const saveService = sale.quotation ? saveQuotation : saveSale;
        const newSale: Sale = {
            ...sale,
            date: customDate ? sale.date : currentLocalDate(),
            salePaymentMethods: saleTotal && !sale.quotation ? sale.salePaymentMethods : [],
        };

        saveService(newSale).then((sale) => {
            resetState(sale);
            showSuccessMessage('Venta registrada correctamente');
        }).catch(() => {
            const minSaleId = Math.min(...getLocalItemsOrEmpty<Sale>(pendingSalesLocalKey).map(s => s.id));
            const newSaleId = minSaleId < 0 ? minSaleId - 1 : -1;
            const pendingSale: Sale = {
                ...newSale,
                id: newSaleId,
                saleItems: newSale.saleItems.map(si => ({
                    ...si,
                    id: newSaleId,
                    saleItemComponents: si.saleItemComponents.map(sic => ({
                        ...sic,
                        id: newSaleId
                    }))
                })),
                salePaymentMethods: newSale.salePaymentMethods.map(spm => ({
                    ...spm,
                    id: newSaleId,
                    paymentMethodName: paymentMethods.find(pm => pm.id === spm.paymentMethodId)?.name ?? ''
                })),
                clientName: clients.find(c => c.id === newSale.clientId)?.name ?? '',
                clientAddressLink: clients.find(c => c.id === newSale.clientId)?.addressLink ?? '',
                businessUnitDescription: businessUnits.find(bu => bu.id === newSale.businessUnitId)?.description ?? ''
            };
            addLocalItem(pendingSalesLocalKey, pendingSale, salesSortFunction);
            resetState(pendingSale);
            showWarningMessage('Venta registrada en dispositivo local');
        });
    };

    const handleReplicate = () => {
        resetState({
            ...sale,
            id: 0,
            quotation: false,
            canceled: false,
            saleItems: sale.saleItems.map(si => ({
                ...si,
                id: 0,
                saleItemComponents:
                    si.saleItemComponents.length === si.quantity &&
                        si.saleItemComponents.every(sic =>
                            sic.productId === si.productId &&
                            sic.productTypeId === si.productTypeId &&
                            sic.measureId === si.measureId &&
                            sic.started &&
                            sic.finished) ? [] : si.saleItemComponents.map(sic => ({
                                ...sic,
                                id: 0
                            }))
            })),
            salePaymentMethods: sale.salePaymentMethods.length ? sale.salePaymentMethods.map(spm => ({
                ...spm,
                id: 0
            })) : [{ ...initialSalePaymentMethod }]
        }, sale.canceled);
    };

    const handleCancel = () => {
        if (sale.id > 0) {
            cancelSale(sale).then((sale) => {
                resetState(sale);
                showSuccessMessage('Venta cancelada correctamente');
            }).catch(() => {
                const canceledSale: Sale = {
                    ...sale,
                    canceled: true
                };
                addLocalItem(pendingSalesLocalKey, canceledSale, salesSortFunction);
                resetState(canceledSale);
                showWarningMessage('Venta cancelada en dispositivo local');
            });
        } else {
            const canceledSale: Sale = {
                ...sale,
                canceled: true
            };
            replaceLocalItem(pendingSalesLocalKey, canceledSale, s => s.id === sale.id);
            resetState(canceledSale);
            showWarningMessage('Venta cancelada en dispositivo local');
        }
    };

    const handlePrint = () => {
        setPrintSale(sale);
    };

    const resetState = (sale?: Sale, customDate?: boolean) => {
        setSale(sale ?? {
            ...initialSale,
            saleItems: [...initialSale.saleItems],
            salePaymentMethods: [...initialSale.salePaymentMethods]
        });
        setCustomDate(customDate ?? false);
    };

    const getSelectedProducts = (product?: StockProduct) => {
        return product && [
            product,
            ...product.allowsInBulk ? products
                .filter(p => p.productId === product.productId && p.productTypeId === product.productTypeId && p.measureUnitsNumber < product.measureUnitsNumber)
                .sort((p1, p2) => p2.measureUnitsNumber - p1.measureUnitsNumber) : []
        ];
    };

    const roundAwayFromZero = (value: number) => Math.round(value);
    const roundToZero = (value: number) => Math.abs(value) % 1 <= 0.5 ? Math.trunc(value) : Math.round(value);
    const roundToFraction = (value: number) => +`${Math.round(+`${value}e+3`)}e-3`;

    return (
        <>
            <ProductSelector
                products={products}
                disabled={sale.id !== 0}
                onProductSelected={handleProductSelected}
                onBarcodeScanned={handleBarcodeScanned}
            />
            <BarcodeUpdater
                products={products}
                barcode={notFoundBarcode}
                onUpdateProductBarcode={handleUpdateProductBarcode}
                onClearBarcode={handleClearBarcode}
            />
            <SelectedProduct
                selectedProducts={selectedProducts}
                allowComponent={activeSaleItemIndex !== undefined && activeSaleItemIndex >= 0}
                onAddSelectedProduct={handleAddSelectedProduct}
                onClearSelectedProduct={handleClearSelectedProduct}
            />
            <SaleItemList
                saleItems={sale.saleItems}
                onSaleItemChange={updateSaleItem}
                onSaleItemRemove={removeSaleItem}
                onActiveIndexChange={(i) => setActiveSaleItemIndex(i)}
            />
            {sale.saleItems.length > 0 && (
                <Form>
                    <hr />
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label>Cliente</Label>
                                <Input type="select" value={sale.clientId} disabled={sale.id !== 0} onChange={(e) => setSale({ ...sale, clientId: +e.target.value })}>
                                    {/* <option value={0}>--Seleccionar--</option> invalid={sale.clientId === 0} */}
                                    {clients.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col>
                            <FormGroup>
                                <Label>Unidad de Negocio</Label>
                                <Input type="select" value={sale.businessUnitId} disabled={sale.id !== 0} onChange={(e) => setSale({ ...sale, businessUnitId: +e.target.value })}>
                                    {/* <option value={0}>--Seleccionar--</option> invalid={sale.businessUnitId === 0} */}
                                    {businessUnits.map(bu => <option key={bu.id} value={bu.id}>{bu.description}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col xs="2">
                            <FormGroup>
                                <Label className="text-nowrap">Fecha y Hora Personalizadas</Label>
                                <CustomInput type="checkbox" id="customDate" className="mt-1 ml-3" checked={customDate} disabled={sale.id !== 0} onChange={(e) => setCustomDate(e.target.checked)} />
                            </FormGroup>
                        </Col>
                        <Col xs="10">
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Input type="datetime-local" step={1} min={'2020-01-01T00:00'} name="date" value={localDateIsoString(sale.date)} disabled={sale.id !== 0 || !customDate} invalid={customDate && invalidDate(sale.date)} onChange={(e) => setSale({ ...sale, date: normalizeDate(e.target.value) })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col>
                            <FormGroup>
                                <CustomInput type="checkbox" id="quotation" label="Guardar como Cotización" checked={sale.quotation} disabled={sale.id !== 0} onChange={(e) => setSale({ ...sale, quotation: e.target.checked })} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label>Notas de Venta</Label>
                                <Input type="textarea" name="remarks" value={sale.remarks} disabled={sale.id !== 0} onChange={updateSale} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <SalePaymentMethodList
                        paymentMethods={paymentMethods}
                        salePaymentMethods={sale.salePaymentMethods}
                        saleTotal={saleTotal}
                        isQuotation={sale.quotation}
                        onAddSalePaymentMethod={handleAddSalePaymentMethod}
                        onUpdateSalePaymentMethod={handleUpdateSalePaymentMethod}
                        onRemoveSalePaymentMethod={handleRemoveSalePaymentMethod}
                    />
                    {sale.id === 0 ? (
                        <>
                            {sale.quotation ? (
                                <Button block type="button" color="info" disabled={!validForm} onClick={handleSave}><FaReceipt /> Registrar Cotización</Button>
                            ) : (
                                <Button block type="button" color="success" disabled={!validForm} onClick={handleSave}><FaCartPlus /> Registrar Venta</Button>
                            )}
                        </>
                    ) : (
                        <>
                            {sale.quotation || sale.canceled ? (
                                <Button block type="button" color="primary" onClick={handleReplicate}><FaCopy /> Replicar Venta</Button>
                            ) : (
                                <SaleCancelButton
                                    sale={sale}
                                    handleCancel={handleCancel}
                                />
                            )}
                        </>
                    )}
                    <Button block type="button" color="warning" disabled={sale.id === 0} onClick={handlePrint}><FaPrint /> Imprimir Recibo</Button>
                </Form>
            )}
            <Button block type="button" color="link" className="mt-3 mb-0 p-0 text-left" tag={Link} to="/venta" onClick={() => resetState()}>Limpiar Venta</Button>
            <Button block type="button" color="link" className="mt-0 mb-3 p-0 text-left" tag={Link} to="/ventas">Ver Ventas Recientes</Button>
            <SaleReceipt
                sale={printSale}
                resetSale={() => setPrintSale(undefined)}
            />
        </>
    );
};

export default connector(SalePage);
