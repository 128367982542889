import { useEffect, useRef, useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { Button, FormGroup, Input, InputGroup, InputGroupAddon, Label } from "reactstrap";
import { User } from "../../models/users/user";

interface LoginInputProps {
    user: User;
    property: keyof User;
    type: 'text' | 'password';
    label: string;
    name?: string;
    readOnly?: boolean;
    onChange?: (name: keyof User, value: string) => void;
}

const LoginInput = ({ user, property, type, label, name, readOnly, onChange }: LoginInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPasswordAutoFill, setIsPasswordAutoFill] = useState<boolean>(false);

    useEffect(() => {
        type === 'password' && setTimeout(() => {
            try {
                setIsPasswordAutoFill(inputRef.current?.matches(':-webkit-autofill') ?? false);
            } catch { }
        }, 0);
    }, [user, type]);

    useEffect(() => {
        isPasswordAutoFill && setShowPassword(false);
    }, [isPasswordAutoFill]);

    const handleClearPassword = () => onChange && onChange(property, '');
    const handleToggleShowPassword = () => setShowPassword(showPassword => !showPassword);

    return (
        <FormGroup>
            <Label>{label}</Label>
            {type === 'text' ? (
                <Input
                    type="text"
                    {...name && {
                        id: name,
                        name: name,
                        autoComplete: name
                    }}
                    readOnly={readOnly}
                    value={user[property]}
                    onChange={e => onChange && onChange(property, e.target.value)}
                />
            ) : (
                <InputGroup>
                    <Input
                        innerRef={inputRef}
                        type={showPassword ? 'text' : 'password'}
                        {...name && {
                            id: name,
                            name: name,
                            autoComplete: name
                        }}
                        className="password"
                        readOnly={readOnly || isPasswordAutoFill}
                        value={user[property]}
                        onChange={e => onChange && onChange(property, e.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                        {isPasswordAutoFill ? (
                            <Button type="button" color="danger" onClick={handleClearPassword}>
                                <FaTimes />
                            </Button>
                        ) : (
                            <Button type="button" color="info" active={showPassword} onClick={handleToggleShowPassword}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </Button>
                        )}
                    </InputGroupAddon>
                </InputGroup>
            )}
        </FormGroup>
    );
};

export default LoginInput;
