import { useEffect } from 'react';
import { Button, Col, CustomInput, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { StockEventItem } from '../../models/stock/stock-event-item';
import NumberFormat from '../common/NumberFormat';

interface StockEventItemFormProps {
    stockEventItem?: StockEventItem;
    //idSuffix: string;
    onStockEventItemChange: (name: string, value: any) => void;
    // onLoading: (loading: boolean) => void;
}

const StockEventItemForm = ({ stockEventItem/*, idSuffix*/, onStockEventItemChange: onStockEventItemChange/*, onLoading*/ }: StockEventItemFormProps) => {
    useEffect(() => {
        if(stockEventItem) {
            if (stockEventItem.inUse) {
                onStockEventItemChange('quantity', 1);
            } else {
                onStockEventItemChange('unitsNumber', stockEventItem!.maxUnitsNumber);
            }
        }
    }, [stockEventItem?.inUse]);


    const handleChange = (name: string, value: any) => {
        onStockEventItemChange(name, value);
    }
    
    return (
        <>
            {stockEventItem && (
                <Form className="mt-2">
                    <hr />
                    <h5>{stockEventItem.fullName}</h5>
                    <Row form>
                        <Col xs="2">
                            <FormGroup>
                                <Label className="text-nowrap">En Uso</Label>
                                <CustomInput type="checkbox" id="inUse" className="mt-1 ml-3" checked={stockEventItem.inUse} onChange={(e) => handleChange('inUse', e.target.checked)} />
                            </FormGroup>
                        </Col>
                        <Col xs="10">
                            {stockEventItem.inUse ? (
                                <FormGroup>
                                <Label>Unidades</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <Button color="info" disabled={stockEventItem.unitsNumber < 1} onClick={() => handleChange('unitsNumber', stockEventItem.unitsNumber - 1)}>-1</Button>
                                        <Button color="info" disabled={stockEventItem.unitsNumber === 0} onClick={() => handleChange('unitsNumber', stockEventItem.unitsNumber - 0.125)}>-⅛</Button>
                                    </InputGroupAddon>
                                    <NumberFormat type="read" format="fraction" name="unitsNumber" value={stockEventItem.unitsNumber} suffix={stockEventItem.unitAbbreviation} onChange={handleChange} />
                                    <InputGroupAddon addonType="append">
                                        <Button color="info" disabled={stockEventItem.unitsNumber === stockEventItem.maxUnitsNumber} onClick={() => handleChange('unitsNumber', stockEventItem.unitsNumber + 0.125)}>+⅛</Button>
                                        <Button color="info" disabled={stockEventItem.unitsNumber > stockEventItem.maxUnitsNumber - 1} onClick={() => handleChange('unitsNumber', stockEventItem.unitsNumber + 1)}>+1</Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>        
                        ) : (
                                <FormGroup>
                                    <Label>Cantidad</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <Button color="info" disabled={stockEventItem.quantity <= 1} onClick={() => handleChange('quantity', stockEventItem.quantity - 1)}>-1</Button>
                                        </InputGroupAddon>
                                        <NumberFormat type="write" format="integer" name="quantity" value={stockEventItem.quantity} min={1} onChange={handleChange} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="info" onClick={() => handleChange('quantity', stockEventItem.quantity + 1)}>+1</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            )}
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default StockEventItemForm;
