import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { PaymentMethod } from "../../models/sales/payment-method";
import { SalePaymentMethod } from "../../models/sales/sale-payment-method";
import NumberFormat from "../common/NumberFormat";

interface SalePaymentMethodListProps {
    paymentMethods: PaymentMethod[];
    salePaymentMethods: SalePaymentMethod[];
    saleTotal: number;
    isQuotation: boolean;
    onAddSalePaymentMethod: () => void;
    onUpdateSalePaymentMethod: (index: number, name: string, value: any) => void;
    onRemoveSalePaymentMethod: (index: number) => void;
}

const SalePaymentMethodList = ({
    paymentMethods,
    salePaymentMethods,
    saleTotal,
    isQuotation,
    onAddSalePaymentMethod,
    onUpdateSalePaymentMethod,
    onRemoveSalePaymentMethod
}: SalePaymentMethodListProps) => {
    const cashPaymentMethodId = 1;
    
    const [cashSalePaymentMethodIndex, setCashSalePaymentMethodIndex] = useState<number>(-1);
    const [receivedAmountChanged, setReceivedAmountChanged] = useState<boolean>(false);

    useEffect(() => {
        setCashSalePaymentMethodIndex(salePaymentMethods.findIndex(spm => spm.paymentMethodId === cashPaymentMethodId));
    }, [salePaymentMethods]);

    useEffect(() => {
        if (salePaymentMethods[cashSalePaymentMethodIndex]) {
            if (salePaymentMethods[cashSalePaymentMethodIndex].id === 0) {
                if (!receivedAmountChanged || salePaymentMethods[cashSalePaymentMethodIndex].paymentAmount > salePaymentMethods[cashSalePaymentMethodIndex].receivedAmount) {
                    setReceivedAmountChanged(false);
                    onUpdateSalePaymentMethod(cashSalePaymentMethodIndex, 'receivedAmount', salePaymentMethods[cashSalePaymentMethodIndex].paymentAmount);
                }
            }
        } else {
            setReceivedAmountChanged(false);
        }
    }, [cashSalePaymentMethodIndex, salePaymentMethods[cashSalePaymentMethodIndex]?.paymentAmount]);

    const handleUpdatePaymentAmount = (index: number, name: string, value: number) => {
        let remainingIncrement = salePaymentMethods[index].paymentAmount - value;
        let currentIndex = index + 1 === salePaymentMethods.length ? 0 : index + 1;
        while (remainingIncrement) {
            onUpdateSalePaymentMethod(currentIndex, name, Math.max(salePaymentMethods[currentIndex].paymentAmount + remainingIncrement, 0));
            remainingIncrement = Math.min(salePaymentMethods[currentIndex].paymentAmount + remainingIncrement, 0);
            currentIndex = currentIndex + 1 === salePaymentMethods.length ? 0 : currentIndex + 1;;
        }
        onUpdateSalePaymentMethod(index, name, value);
    };

    const handleRemoveSalePaymentMethod = (index: number) => {
        const nextIndex = index + 1 === salePaymentMethods.length ? 0 : index + 1;
        onUpdateSalePaymentMethod(nextIndex, 'paymentAmount', salePaymentMethods[nextIndex].paymentAmount + salePaymentMethods[index].paymentAmount);
        onRemoveSalePaymentMethod(index);
    };

    const handleUpdateReceivedAmount = (index: number, name: string, value: number) => {
        setReceivedAmountChanged(true);
        onUpdateSalePaymentMethod(index, name, value);
    };

    return (
        <>
            {saleTotal > 0 && !isQuotation && (
                <>
                    <Row form>
                        <Col xs="6">
                            <Label>Forma de Pago</Label>
                        </Col>
                        <Col xs="5">
                            <Label>Importe</Label>
                        </Col>
                        <Col xs="1"></Col>
                    </Row>
                    {salePaymentMethods.map((spm, i) => (
                        <Row form key={i}>
                            <Col xs="6">
                                <FormGroup>
                                    <Input type="select" value={spm.paymentMethodId} disabled={spm.id !== 0} invalid={spm.paymentMethodId === 0} onChange={e => onUpdateSalePaymentMethod(i, 'paymentMethodId', +e.target.value)}>
                                        <option value={0}>--Seleccionar--</option>
                                        {paymentMethods.filter(pm => cashSalePaymentMethodIndex < 0 || spm.paymentMethodId === cashPaymentMethodId || pm.id !== cashPaymentMethodId).map(pm => <option key={pm.id} value={pm.id}>{pm.name}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="5">
                                <FormGroup>
                                    <NumberFormat
                                        type={spm.id !== 0 ? 'read' : salePaymentMethods.length > 1 ? 'write' : 'plain'}
                                        format="currency"
                                        name="paymentAmount"
                                        value={spm.paymentAmount}
                                        max={saleTotal}
                                        invalid={spm.paymentAmount === 0}
                                        onChange={(n, v) => handleUpdatePaymentAmount(i, n, v)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="1">
                                {spm.id === 0 && salePaymentMethods.length > 1 && <Button close className="text-danger mt-1" onClick={() => handleRemoveSalePaymentMethod(i)} />}
                            </Col>
                        </Row>
                    ))}
                    {salePaymentMethods[cashSalePaymentMethodIndex] && (
                        <Row form>
                            <Col xs="5">
                                <FormGroup>
                                    <Label>Pago en Efectivo</Label>
                                    <NumberFormat
                                        type={salePaymentMethods[cashSalePaymentMethodIndex].id !== 0 ? 'read' : 'write'}
                                        format="currency"
                                        name="receivedAmount"
                                        value={salePaymentMethods[cashSalePaymentMethodIndex].receivedAmount}
                                        invalid={salePaymentMethods[cashSalePaymentMethodIndex].receivedAmount < salePaymentMethods[cashSalePaymentMethodIndex].paymentAmount}
                                        onChange={(n, v) => handleUpdateReceivedAmount(cashSalePaymentMethodIndex, n, v)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="1"></Col>
                            <Col xs="5">
                                <FormGroup>
                                    <Label>Cambio</Label>
                                    <NumberFormat
                                        type={salePaymentMethods[cashSalePaymentMethodIndex].id !== 0 ? 'read' : 'plain'}
                                        format="currency"
                                        value={Math.max(salePaymentMethods[cashSalePaymentMethodIndex].receivedAmount - salePaymentMethods[cashSalePaymentMethodIndex].paymentAmount, 0)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs="1"></Col>
                        </Row>
                    )}
                </>
            )}
            <Row form>
                <Col xs="6" className="text-right">
                    <Label className="mt-2 font-weight-bold">Importe Total</Label>
                </Col>
                <Col xs="5" className="text-center">
                    <FormGroup>
                        <NumberFormat type="plain" format="currency" value={saleTotal} bold={true} />
                    </FormGroup>
                </Col>
                <Col xs="1">
                    {saleTotal > 0 && !isQuotation && <Button type="button" color="success" size="sm" className="mt-1 ml-n3" disabled={salePaymentMethods[0].id !== 0} onClick={onAddSalePaymentMethod}><FaPlus /></Button>}
                </Col>
            </Row>
        </>
    );
};

export default SalePaymentMethodList;
