import { Button, Col, CustomInput, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { FaPlus } from "react-icons/fa";
import { StockProduct } from "../../models/products/stock-product"
import NumberFormat from "../common/NumberFormat"
import { useEffect, useState } from "react";

interface SelectedProductProps {
    selectedProducts?: StockProduct[];
    allowComponent: boolean;
    onAddSelectedProduct: (product?: StockProduct, component?: StockProduct) => void;
    onClearSelectedProduct: () => void;
}

const SelectedProduct = ({
    selectedProducts,
    allowComponent,
    onAddSelectedProduct,
    onClearSelectedProduct
}: SelectedProductProps) => {
    const [selectedProduct, setSelectedProduct] = useState<StockProduct>();

    useEffect(() => {
        if (selectedProducts && selectedProducts.length) {
            setSelectedProduct(selectedProducts[0]);
        }
    }, [selectedProducts]);
    
    return (
        <>
            {selectedProducts && selectedProducts.length && selectedProduct && (
                <Modal isOpen={true} toggle={onClearSelectedProduct}>
                    <ModalHeader toggle={onClearSelectedProduct}>{selectedProducts[0].fullName}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <div className="mr-n3">
                                    {selectedProducts.map((p, i) => (
                                        <CustomInput key={i} inline type="radio" id={`measure-${i}`} name="measure" label={p.measureName} checked={p === selectedProduct} onChange={() => setSelectedProduct(p)} />
                                    ))}
                                </div>
                            </FormGroup>
                        </Form>
                        <Row className="mx-n1 mb-2">
                            <Col xs="3" className="px-1 text-center">
                                <Label>Precio</Label>
                                <br />
                                <NumberFormat type="text" format="currency" value={selectedProduct.salePrice} />
                            </Col>
                            <Col xs="6" className="px-1 text-center">
                                <Label>Descuento</Label>
                                <br />
                                <NumberFormat type="text" format="percentage" value={selectedProduct.saleDiscount / selectedProduct.salePrice * 100} />
                                <span className="mx-2"></span>
                                <NumberFormat type="text" format="currency" value={selectedProduct.saleDiscount} />
                            </Col>
                            <Col xs="3" className="px-1 text-center">
                                <Label>Subtotal</Label>
                                <br />
                                <b>
                                    <NumberFormat type="text" format="currency" value={selectedProduct.salePrice - selectedProduct.saleDiscount} />
                                </b>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {allowComponent && selectedProduct.allowsInBulk && (
                            <Button color="warning" onClick={() => onAddSelectedProduct(undefined, selectedProduct)}><FaPlus /> Componente</Button>
                        )}
                        <Button color="success" onClick={() => onAddSelectedProduct(selectedProduct, selectedProduct !== selectedProducts[0] ? selectedProducts[0]: undefined)}><FaPlus /> Producto</Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
}

export default SelectedProduct;
