import { FaCartPlus, FaWarehouse } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const Home = () => (
  <Row xs="1" md="3">
    <Col className="mt-5">
      <Button block color="primary" tag={Link} to="/venta">
        <div className="display-1 pt-2 d-flex flex-column justify-content-center align-items-center">
          <FaCartPlus />
          <div className="display-3">Ventas</div>
        </div>
      </Button>
    </Col>
    <Col className="mt-5">
      <Button block color="primary" tag={Link} to="/inventario">
        <div className="display-1 pt-2 d-flex flex-column justify-content-center align-items-center">
          <FaWarehouse />
          <div className="display-3">Inventario</div>
        </div>
      </Button>
    </Col>
  </Row>

  // <div>
  //   <h1>Hello, world!</h1>
  //   <p>Welcome to your new single-page application, built with:</p>
  //   <ul>
  //     <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
  //     <li><a href='https://facebook.github.io/react/'>React</a> and <a href='https://redux.js.org/'>Redux</a> for client-side code</li>
  //     <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
  //   </ul>
  //   <p>To help you get started, we have also set up:</p>
  //   <ul>
  //     <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
  //     <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
  //     <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
  //   </ul>
  //   <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
  // </div>
);

export default connect()(Home);
