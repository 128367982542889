export const currentLocalDate = () => new Date();

export const localDateFullString = (date: Date) => normalizeDate(date).toLocaleString();
export const localDateDateString = (date: Date) => normalizeDate(date).toLocaleDateString();
export const localDateTimeString = (date: Date) => normalizeDate(date).toLocaleTimeString();

export const localDateIsoString = (date: Date) => {
  const normalizedDate = normalizeDate(date);
  return isNaN(normalizedDate.getTime()) ? '' : new Date(normalizedDate.getTime() - normalizedDate.getTimezoneOffset() * 60000).toISOString().replace(/(:00)?\.\d+Z$/, '')
};

export const invalidDate = (date: Date) => {
  const normalizedDate = normalizeDate(date);
  return isNaN(normalizedDate.getTime()) || normalizedDate.getTime() < normalizeDate('2020-01-01T00:00').getTime();
};

export const salesSortFunction = (a: { date: Date }, b: { date: Date }) => normalizeDate(b.date).getTime() - normalizeDate(a.date).getTime();

export const normalizeDate = (date: Date | string) => typeof date === 'string' ? new Date(date) : date;
