import { LOAD_BUSINESS_UNITS_SUCCESS, LOAD_CLIENTS_SUCCESS, LOAD_PAYMENT_METHODS_SUCCESS, LOAD_RECENT_SALES_SUCCESS, SalesActions } from "./actions";
import { initialState, SalesState } from "./state";

export const salesReducer = (state: SalesState = initialState, action: SalesActions): SalesState => {
    switch (action.type) {
        case LOAD_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.clients
            };
        case LOAD_BUSINESS_UNITS_SUCCESS:
            return {
                ...state,
                businessUnits: action.businessUnits
            };
        case LOAD_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                paymentMethods: action.paymentMethods
            };
        case LOAD_RECENT_SALES_SUCCESS:
            return {
                ...state,
                recentSales: action.recentSales
            };
        default:
            return state;
    }
}
