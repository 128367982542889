import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { Sale } from "../../models/sales/sale";
import { useToastMessage } from "../../hooks/use-toast-message";
import { salesSortFunction } from "../../services/dates";
import { getLocalItems, getLocalItemsOrEmpty, pendingSalesLocalKey, recentSalesLocalKey, setLocalItems } from "../../services/locals";
import { saveSales } from "../../services/sales";
import { ApplicationState } from "../../store";
import { loadRecentSales, loadRecentSalesSuccess, SalesActions } from "../../store/sales/actions";
import SaleList from "./SaleList";

const connector = connect(
    (state: ApplicationState) => ({ ...state.sales }),
    (dispatch: ThunkDispatch<ApplicationState, void, SalesActions>) => ({
        loadRecentSales: () => dispatch(loadRecentSales()),
        loadRecentSalesSuccess: (recentSales: Sale[]) => dispatch(loadRecentSalesSuccess(recentSales))
    })
);

const SalesPage = ({
    recentSales,
    loadRecentSales,
    loadRecentSalesSuccess
}: ConnectedProps<typeof connector>) => {
    const history = useHistory();
    
    const { showSuccessMessage, showWarningMessage, showErrorMessage } = useToastMessage();
    
    const handleUploadSales = () => {
        const pendingSales = getLocalItemsOrEmpty<Sale>(pendingSalesLocalKey).reverse();
        saveSales(pendingSales).then(saleIds => {
            const remainingSales = pendingSales.filter(s => !saleIds.includes(s.id));
            setLocalItems(pendingSalesLocalKey, remainingSales, salesSortFunction);
            if (remainingSales.length) {
                showWarningMessage('Ventas registradas parcialmente');
            } else {
                showSuccessMessage('Ventas registradas correctamente');
            }
            handleUpdateSales();
        }).catch(() => {
            showErrorMessage('Error al registrar las ventas');
        });
    };

    const handleUpdateSales = () => {
        loadRecentSales()
            .then(value => setLocalItems(recentSalesLocalKey, value.recentSales))
            .catch(() => {
                const recentSales = getLocalItems<Sale>(recentSalesLocalKey);
                if (recentSales) {
                    loadRecentSalesSuccess(recentSales);
                    showWarningMessage('Se cargó la versión anterior de las ventas recientes');
                } else {
                    showErrorMessage('Error al cargar las ventas recientes');
                }
            });
    };

    const handleFindSale = (saleId: number) => {
        history.push(`/venta/${saleId}`);
    };

    const handleSelectSale = (saleId: number) => {
        history.push(`/venta/${saleId}`);
    };

    return (
        <SaleList
            pendingSales={getLocalItemsOrEmpty<Sale>(pendingSalesLocalKey)}
            recentSales={recentSales}
            onUploadSales={handleUploadSales}
            onUpdateSales={handleUpdateSales}
            onFindSale={handleFindSale}
            onSelectSale={handleSelectSale}
        />
    );
};

export default connector(SalesPage);
