import { Container } from 'reactstrap';
import { useHttp } from '../hooks/use-http';
import NavMenu from './NavMenu';
import LoadingSpinner from "./common/LoadingSpinner";
import LoginDialog from './common/LoginDialog';
import ToastMessage from "./common/ToastMessage";

interface LayoutProps {
    children?: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
    const { loadingCount, isAuthenticating, authenticatedUser, startLogin, cancelLogin, logout } = useHttp();

    return (
        <>
            <NavMenu startLogin={startLogin} />
            <Container>
                {children}
            </Container>
            <LoadingSpinner visible={loadingCount > 0} />
            <LoginDialog isAuthenticating={isAuthenticating} authenticatedUser={authenticatedUser} cancelLogin={cancelLogin} logout={logout} />
            <ToastMessage />
        </>
    );
};

export default Layout;
