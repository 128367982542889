import { useEffect, useState } from "react";
import { FaUserCheck, FaUserCog, FaUserEdit, FaUserTimes } from "react-icons/fa";
import { useHistory } from "react-router";
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useToastMessage } from "../../hooks/use-toast-message";
import { User } from "../../models/users/user";
import { changePassword, getAccessToken, resetPassword } from "../../services/users";
import LoginInput from "./LoginInput";

interface LoginDialogProps {
    isAuthenticating: boolean;
    authenticatedUser?: User;
    cancelLogin: () => void;
    logout: () => void;
}

const initialUser: User = {
    name: '',
    email: '',
    nickname: '',
    password: '',
    nicknameOrEmail: '',
    newPassword: '',
    confirmPassword: '',
    roleName: ''
};

const LoginDialog = ({ isAuthenticating, authenticatedUser, cancelLogin, logout }: LoginDialogProps) => {
    const [user, setUser] = useState<User>({ ...initialUser });
    const [activeForm, setActiveForm] = useState<'login' | 'logout' | 'reset' | 'change'>('login');

    const history = useHistory();

    const { showSuccessMessage, showErrorMessage } = useToastMessage();

    const handleChange = <T extends keyof User>(name: T, value: User[T]) => setUser(user => ({ ...user, [name]: value }));

    const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        getAccessToken(user)
            .then(() => history.replace(history.location))
            .catch(() => showErrorMessage('Error al iniciar sesión'));
    };

    const handleLogout = () => {
        logout();
    };

    const handleResetPassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetPassword(user)
            .then(() => {
                history.replace(history.location);
                resetState(isAuthenticating, authenticatedUser);
                showSuccessMessage('Contraseña restablecida correctamente');
            })
            .catch(() => showErrorMessage('Error al restablecer la contraseña'));
    };

    const handleChangePassword = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        changePassword(user)
            .then(() => {
                logout();
                history.replace(history.location);
                showSuccessMessage('Contraseña modificada correctamente');
            })
            .catch(() => showErrorMessage('Error al modificar la contraseña'));
    };

    const resetState = (isAuthenticating: boolean, authenticatedUser?: User, activeForm?: 'login' | 'logout' | 'reset' | 'change') => {
        if (isAuthenticating) {
            setUser({ ...initialUser, nickname: authenticatedUser?.nickname ?? '' });
            setActiveForm(activeForm ?? (authenticatedUser ? 'logout' : 'login'));
        }
    };

    useEffect(() => {
        resetState(isAuthenticating, authenticatedUser);
    }, [isAuthenticating, authenticatedUser]);

    return (
        <>
            {isAuthenticating && (
                <Modal isOpen={true} toggle={cancelLogin}>
                    <ModalHeader className="bg-info text-white" toggle={cancelLogin}>
                        Acceso
                    </ModalHeader>
                    <ModalBody>
                        {activeForm === 'login' && (
                            <Form id="login" autoComplete="on" onSubmit={handleLogin}>
                                <LoginInput user={user} property="nickname" type="text" label="Usuario" name="username" onChange={handleChange} />
                                <LoginInput user={user} property="password" type="password" label="Contraseña" name="current-password" onChange={handleChange} />
                            </Form>
                        )}
                        {activeForm === 'logout' && (
                            <dl>
                                <dt>Nombre</dt>
                                <dd>{authenticatedUser?.name}</dd>
                                <dt>Correo Electrónico</dt>
                                <dd>{authenticatedUser?.email}</dd>
                                <dt>Usuario</dt>
                                <dd>{authenticatedUser?.nickname}</dd>
                                <dt>Perfil</dt>
                                <dd>{authenticatedUser?.roleName}</dd>
                            </dl>
                        )}
                        {activeForm === 'reset' && (
                            <Form id="reset" autoComplete="on" onSubmit={handleResetPassword}>
                                <LoginInput user={user} property="nicknameOrEmail" type="text" label="Usuario o Correo Electrónico" name="username" onChange={handleChange} />
                            </Form>
                        )}
                        {activeForm === 'change' && (
                            <Form id="change" autoComplete="on" onSubmit={handleChangePassword}>
                                <LoginInput user={user} property="nickname" type="text" label="Usuario" name="username" readOnly />
                                <LoginInput user={user} property="password" type="password" label="Contraseña" name="current-password" onChange={handleChange} />
                                <LoginInput user={user} property="newPassword" type="password" label="Nueva Contraseña" name="new-password" onChange={handleChange} />
                                <LoginInput user={user} property="confirmPassword" type="password" label="Confirmar Contraseña" onChange={handleChange} />
                            </Form>
                        )}
                    </ModalBody>
                    <ModalFooter style={{ justifyContent: "space-between" }}>
                        {activeForm === 'login' && (
                            <>
                                <Button type="button" color="link" className="p-0" onClick={() => resetState(isAuthenticating, authenticatedUser, 'reset')}>Restablecer Contraseña</Button>
                                <Button type="submit" color="success" form="login" disabled={!user.nickname || !user.password}>
                                    <FaUserCheck /> Iniciar Sesión
                                </Button>
                            </>
                        )}
                        {activeForm === 'logout' && (
                            <>
                                <Button type="button" color="link" className="p-0" onClick={() => resetState(isAuthenticating, authenticatedUser, 'change')}>Modificar Contraseña</Button>
                                <Button type="button" color="danger" onClick={handleLogout}>
                                    <FaUserTimes /> Cerrar Sesión
                                </Button>
                            </>
                        )}
                        {activeForm === 'reset' && (
                            <>
                                <Button type="button" color="link" className="p-0" onClick={() => resetState(isAuthenticating, authenticatedUser, 'login')}>Iniciar Sesión</Button>
                                <Button type="submit" color="warning" form="reset" disabled={!user.nicknameOrEmail}>
                                    <FaUserCog /> Restablecer Contraseña
                                </Button>
                            </>
                        )}
                        {activeForm === 'change' && (
                            <>
                                <Button type="button" color="link" className="p-0" onClick={() => resetState(isAuthenticating, authenticatedUser, 'logout')}>Cerrar Sesión</Button>
                                <Button type="submit" color="primary" form="change" disabled={!user.nickname || !user.password || !user.newPassword || !user.confirmPassword || user.newPassword !== user.confirmPassword}>
                                    <FaUserEdit /> Modificar Contraseña
                                </Button>
                            </>
                        )}
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default LoginDialog;
