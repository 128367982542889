import { Button, Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { StockEventItem } from '../../models/stock/stock-event-item'

interface StockEventItemListProps {
    stockEventItems: StockEventItem[];
    onStockEventItemRemove: (index: number) => void;
}

const StockEventItemList = ({ stockEventItems, onStockEventItemRemove }: StockEventItemListProps) => {
    return (
        <>
            {stockEventItems.length > 0 && <hr />}
            {stockEventItems.map((si, i) => (
                <Card key={i} className="mx-n2">
                    <CardBody className="p-2">
                        {si.id === 0 && <Button close className="text-danger mt-n1" onClick={() => onStockEventItemRemove(i)} />}
                        <CardTitle tag="h6" className="mb-1">{si.fullName}</CardTitle>
                        <CardText>{si.quantity}&times;{si.unitsNumber}{si.unitAbbreviation}</CardText>
                    </CardBody>
                </Card>
            ))}
        </>
    );
};

export default StockEventItemList;
