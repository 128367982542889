import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FaBarcode } from "react-icons/fa";
import { StockProduct } from '../../models/products/stock-product';
import ProductSelector from './ProductSelector';
import { useEffect, useState } from 'react';

interface BarcodeUpdaterProps {
    products: StockProduct[];
    barcode?: string;
    onUpdateProductBarcode: (productId: number, barcode: string) => void;
    onClearBarcode: () => void;
}

const BarcodeUpdater = ({
    products,
    barcode,
    onUpdateProductBarcode,
    onClearBarcode
}: BarcodeUpdaterProps) => {
    const [selectedProductId, setSelectedProductId] = useState<number>();

    useEffect(() => {
        setSelectedProductId(undefined);
    }, [barcode]);

    const handleProductSelected = (selectedProductId: number) => {
        setSelectedProductId(selectedProductId);
    };

    const handleUpdateBarcode = () => {
        onUpdateProductBarcode(selectedProductId!, barcode!);
    };

    return (
        <>
            {barcode && (
                <Modal isOpen={true} toggle={onClearBarcode}>
                    <ModalHeader className="bg-danger text-white" toggle={onClearBarcode}>
                        Producto no encontrado [{barcode}]
                    </ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label>Producto</Label>
                                <ProductSelector
                                    products={products}
                                    onProductSelected={handleProductSelected}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="submit"
                            color="danger"
                            disabled={!selectedProductId}
                            onClick={handleUpdateBarcode}
                        >
                            <FaBarcode /> Actualizar Código de Barras
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </>
    );
};

export default BarcodeUpdater;
