import { Client } from "../../models/sales/client";
import { BusinessUnit } from "../../models/sales/business-unit";
import { PaymentMethod } from "../../models/sales/payment-method";
import { Sale } from "../../models/sales/sale";

export interface SalesState {
    clients: Client[];
    businessUnits: BusinessUnit[];
    paymentMethods: PaymentMethod[];
    recentSales: Sale[];
}

export const initialState: SalesState = {
    clients: [],
    businessUnits: [],
    paymentMethods: [],
    recentSales: []
}
