import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Sale } from "../../models/sales/sale";
import { localDateFullString } from '../../services/dates';
import NumberFormat from "../common/NumberFormat";

interface SaleListItemProps {
    sale: Sale;
    onSelectSale: (id: number) => void;
}

const SaleListItem = ({ sale, onSelectSale }: SaleListItemProps) => {
    return (
        <Card outline color={sale.quotation ? 'info' : sale.canceled ? 'danger' : 'success'} className="mt-2 mx-n2" style={{ borderWidth: '2px' }} onClick={() => onSelectSale(sale.id)}>
            <CardHeader className="p-2">
                <Row className="mx-0">
                    <Col xs="8" className="px-0">
                        <h6 className="mb-0">{localDateFullString(sale.date)}</h6>
                    </Col>
                    <Col xs="4" className="px-0 text-right">
                        <h6 className="mb-0">
                            <NumberFormat type="text" format="currency" value={sale.saleItems.reduce((st, si) => st + (si.salePrice - si.saleDiscount) * si.quantity, 0)} />
                        </h6>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="p-2">
                {sale.saleItems.map((si, i) => (
                    <Row key={i} className="mx-n1">
                        <Col xs="8" className="px-1">
                            <div className="pr-2 text-truncate">{si.fullName}</div>
                        </Col>
                        <Col xs="1" className="px-1 text-right">
                            <div className="ml-n3">&times;{si.quantity}</div>
                        </Col>
                        <Col xs="3" className="px-1 text-right">
                            <NumberFormat type="text" format="currency" value={(si.salePrice - si.saleDiscount) * si.quantity} />
                        </Col>
                    </Row>
                ))}
                <hr className="my-1" />
                <div className="text-truncate">Cliente: {sale.clientAddressLink ? <a target="_blank" className="btn btn-link d-inline align-baseline p-0" href={sale.clientAddressLink}>{sale.clientName}</a> : sale.clientName}</div>
                <div className="text-truncate">Unidad de Negocio: {sale.businessUnitDescription}</div>
                {sale.salePaymentMethods.length > 0 && (
                    <>
                        <hr className="my-1" />
                        <div>Forma{sale.salePaymentMethods.length > 1 ? 's' : ''} de Pago: {sale.salePaymentMethods.map(spm => spm.paymentMethodName).join(', ')}</div>
                    </>
                )}
            </CardBody>
        </Card>
    );
};

export default SaleListItem;
