import { useEffect, useState } from "react";
import { User } from "../models/users/user";
import { accessTokenSetEvent, beginHttpEvent, beginLoginEvent, completeHttpEvent, completeLoginEvent, completeSubscriptions, emitter, setAccessToken } from "../services/http";

export const useHttp = () => {
    const [loadingCount, setLoadingCount] = useState<number>(0);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<User>();

    const startLogin = () => emitter.emit(beginLoginEvent);
    const cancelLogin = () => emitter.emit(completeLoginEvent);
    const logout = () => setAccessToken('');

    useEffect(() => {
        emitter.on(beginHttpEvent, () => setLoadingCount(lc => lc + 1));
        emitter.on(completeHttpEvent, () => setLoadingCount(lc => lc - 1));
        emitter.on(beginLoginEvent, () => setIsAuthenticating(true));
        emitter.on(completeLoginEvent, () => setIsAuthenticating(false));

        emitter.on(accessTokenSetEvent, (accessToken: string) => {
            if (accessToken) {
                const tokenObject = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString());
                setAuthenticatedUser({
                    name: tokenObject['name'],
                    email: tokenObject['email'],
                    nickname: tokenObject['nameid'],
                    password: '',
                    nicknameOrEmail: '',
                    newPassword: '',
                    confirmPassword: '',
                    roleName: tokenObject['role']
                })
            } else {
                setAuthenticatedUser(undefined);
            }
        });

        completeSubscriptions();
        
        return () => {
            emitter.off(beginHttpEvent);
            emitter.off(completeHttpEvent);
            emitter.off(beginLoginEvent);
            emitter.off(completeLoginEvent);
            emitter.off(accessTokenSetEvent);
        };
    }, []);

    return {
        loadingCount,
        isAuthenticating,
        authenticatedUser,
        startLogin,
        cancelLogin,
        logout
    };
};
