import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { ThunkDispatch } from "redux-thunk";
import { StockProduct } from '../../models/products/stock-product';
import { StockEvent } from '../../models/stock/stock-event';
import { StockEventItem } from '../../models/stock/stock-event-item';
import { currentLocalDate } from '../../services/dates';
import { updateProductBarcode } from "../../services/products";
import { getActiveStockEvent, saveStockEvent } from "../../services/stock";
import { ApplicationState } from '../../store';
import { loadProducts, ProductsActions } from '../../store/products/actions';
import ProductSelector from "../common/ProductSelector";
import BarcodeUpdater from "../common/BarcodeUpdater";
import StockEventItemForm from "./StockEventItemForm";
import StockEventItemList from "./StockEventItemList";
import { FaUpload, FaWarehouse } from 'react-icons/fa';

const connector = connect(
    (state: ApplicationState) => ({ ...state.products }),
    (dispatch: ThunkDispatch<ApplicationState, void, ProductsActions>) => ({
        loadProducts: () => dispatch(loadProducts())
    })
);

export const StockEventPage = ({
    products,
    loadProducts,
}: ConnectedProps<typeof connector>) => {
    const maxUnsavedItems = 15;

    const [stockEvent, setStockEvent] = useState<StockEvent>({
        id: 0,
        dateStarted: currentLocalDate(),
        stockEventItems: []
    });

    const [notFoundBarcode, setNotFoundBarcode] = useState<string>();
    const [activeStockEventItem, setActiveStockEventItem] = useState<StockEventItem>();

    useEffect(() => {
        if (products.length === 0) {
            loadProducts();
        }
        
        getActiveStockEvent().then(stockEvent => {
            setStockEvent(stockEvent ?? {
                id: 0,
                dateStarted: currentLocalDate(),
                stockEventItems: []
            });
        });
    }, []);

    const handleProductSelected = (id: number) => {
        stackProduct(id ? products.find(p => p.id === id) : undefined);
    };

    const handleBarcodeScanned = (barcode: string) => {
        const product = barcode ? products.find(p => p.barCode === barcode) : undefined;
        stackProduct(product, product ? undefined : barcode);
    };

    const handleUpdateProductBarcode = (productId: number, barcode: string) => {
        updateProductBarcode(productId, barcode).then(() => {
            setNotFoundBarcode(undefined);
        });
    };

    const handleClearBarcode = () => {
        setNotFoundBarcode(undefined);
    };

    const stackProduct = (product?: StockProduct, notFoundBarcode?: string) => {
        stackActiveStockEventItem();

        setActiveStockEventItem(product ? {
            id: 0,
            productId: product.productId,
            productTypeId: product.productTypeId,
            measureId: product.measureId,
            inUse: false,
            quantity: 1,
            unitsNumber: product.measureUnitsNumber,
            fullName: product.fullName,
            unitAbbreviation: product.measureUnitAbbreviation,
            maxUnitsNumber: product.measureUnitsNumber
        } : undefined);

        setNotFoundBarcode(notFoundBarcode);
    }

    const stackActiveStockEventItem = () => {
        if (activeStockEventItem) {
            setStockEvent({
                ...stockEvent,
                stockEventItems: [
                    activeStockEventItem,
                    ...stockEvent.stockEventItems
                ]
            });
            setActiveStockEventItem(undefined);
        }
    };

    const handleStockEventItemChange = (name: string, value: any) => {
        setActiveStockEventItem({
            ...activeStockEventItem!,
            [name]: value
        });
    };

    const handleStockEventItemRemove = (index: number) => {
        setStockEvent({
            ...stockEvent,
            stockEventItems: stockEvent.stockEventItems.filter((_, i) => i !== index)
        });
    };

    const handleSave = (finish: boolean) => {        
        saveStockEvent({
            ...stockEvent,
            dateStarted: !stockEvent.id ? currentLocalDate() : stockEvent.dateStarted,
            dateFinished: finish ? currentLocalDate() : stockEvent.dateFinished,
            stockEventItems: [
                ...(activeStockEventItem ? [activeStockEventItem] : []),
                ...stockEvent.stockEventItems.filter(sei => sei.id === 0)
            ].reverse()
        }).then((stockEvent) => {
            setStockEvent(stockEvent);
            setActiveStockEventItem(undefined);
        });

        //????? stackActiveStockEventItem();
    };

    return (
        <>
            <ProductSelector
                products={products}
                onProductSelected={handleProductSelected}
                onBarcodeScanned={handleBarcodeScanned}
            />
            <BarcodeUpdater
                products={products}
                barcode={notFoundBarcode}
                onUpdateProductBarcode={handleUpdateProductBarcode}
                onClearBarcode={handleClearBarcode}
            />
            <StockEventItemForm
                stockEventItem={activeStockEventItem}
                onStockEventItemChange={handleStockEventItemChange}
            />
            <StockEventItemList
                stockEventItems={stockEvent.stockEventItems}
                onStockEventItemRemove={handleStockEventItemRemove}
            />
            {stockEvent.stockEventItems.length > 0 && (
                <Form>
                    <hr />
                    <Button type="button" block color="primary" onClick={() => handleSave(false)}><FaUpload /> Guardar y Continuar</Button>
                    <Button type="button" block color="success" onClick={() => handleSave(true)}><FaWarehouse /> Guardar y Terminar</Button>
                </Form>
            )}
        </>
    );
}

export default connector(StockEventPage);
