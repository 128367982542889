import { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Collapse, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { SaleItem } from '../../models/sales/sale-item'
import SaleItemSummary from "./SaleItemSummary";
import SaleItemForm from "./SaleItemForm";

interface SaleItemListProps {
    saleItems: SaleItem[];
    onSaleItemChange: (index: number, name: string, value: any) => void;
    onSaleItemRemove: (index: number) => void;
    onActiveIndexChange: (activeIndex: number) => void;
}

const SaleItemList = ({ saleItems, onSaleItemChange, onSaleItemRemove, onActiveIndexChange }: SaleItemListProps) => {
    const [advancedEdition, setAdvancedEdition] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [previousLength, setPreviousLength] = useState(0);

    useEffect(() => {
        if (saleItems.length === 0) {
            setActiveIndex(-1);
        }
        if (saleItems.length > previousLength) {
            setActiveIndex(saleItems.length - 1);
        }
        setPreviousLength(saleItems.length);
    }, [saleItems.length]);

    useEffect(() => {
        onActiveIndexChange(activeIndex);
    }, [activeIndex]);

    const handleRemove = (index: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        
        if (index === activeIndex) {
            setActiveIndex(-1);
        } else if (index < activeIndex) {
            setActiveIndex(activeIndex - 1);
        }
        onSaleItemRemove(index);
    };

    return (
        <>
            {saleItems.length > 0 && (
                <>
                    <hr />
                    <Row className="mx-0">
                        <Col xs="6" className="px-0">
                            <h5>Detalle de Venta</h5>
                        </Col>
                        <Col xs="6" className="px-0 text-right">
                            <FormGroup className="mb-2">
                                <CustomInput type="checkbox" id="advancedEdition" label="Edición Avanzada" checked={advancedEdition} disabled={saleItems[0].id !== 0} onChange={() => setAdvancedEdition(!advancedEdition)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </>
            )}
            {saleItems.map((saleItem, index) => (
                <Card key={index} className={`mx-n2 ${saleItem.saleItemComponents.length && saleItem.saleItemComponents.reduce((un, sic) => un + sic.unitsNumber, 0) < saleItem.measureUnitsNumber * saleItem.quantity ? 'border-danger' : ''}`}>
                    <CardHeader className="p-2" onClick={() => setActiveIndex(index === activeIndex ? -1 : index)}>
                        {saleItem.id === 0 && <Button close className="text-danger mt-n1" onClick={(event) => handleRemove(index, event)} />}
                        <SaleItemSummary saleItem={saleItem} />
                    </CardHeader>
                    <Collapse className="" isOpen={index === activeIndex}>
                        <CardBody className="p-2">
                            <SaleItemForm
                                saleItem={saleItem}
                                advacedEdition={advancedEdition}
                                idSuffix={`${index}`}
                                onChange={(name, value) => onSaleItemChange(index, name, value)}
                            />
                        </CardBody>
                    </Collapse>
                </Card>
            ))}
        </>
    );
};

export default SaleItemList;
