import { useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface SaleFinderProps {
  isFinding: boolean;
  onFindSale: (saleId: number) => void;
  onCloseFinder: () => void;
}

const SaleFinder = ({
  isFinding,
  onFindSale,
  onCloseFinder
}: SaleFinderProps) => {
  const [saleId, setSaleId] = useState<string>('');

  const handleFindSale = () => {
    onFindSale(+saleId);
  };

  const handleCloseFinder = () => {
    setSaleId('');
    onCloseFinder();
  };

  const handleInputChange = (value: string) => {
    setSaleId(value.replace(/\D/g, ''));
  };

  return (
    <>
      {isFinding && (
        <Modal isOpen={true} toggle={handleCloseFinder}>
          <ModalHeader toggle={handleCloseFinder}>Buscar Venta</ModalHeader>
          <ModalBody>
            <Form onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <Label>Número de Venta</Label>
                <Input type="number" min="1" step="1" className="text-center" value={saleId} onChange={e => handleInputChange(e.target.value)} />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" disabled={!saleId} onClick={handleFindSale}>
              <FaSearch /> Buscar Venta
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default SaleFinder;
