import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import { ProductsState } from './products/state';
import { SalesState } from './sales/state';
import { productsReducer } from './products/reducer';
import { salesReducer } from './sales/reducer';

// The top-level state object
export interface ApplicationState {
    counter: Counter.CounterState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    products: ProductsState;
    sales: SalesState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    counter: Counter.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    products: productsReducer,
    sales: salesReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction, TReturn> {
    (dispatch: (action: TAction) => TReturn, getState: () => ApplicationState): Promise<TReturn>;
}
