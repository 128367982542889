import { TinyEmitter } from 'tiny-emitter'

const unauthorizedStatusCode = 401;

const usersApiUrl = 'api/users';
const loginApiUrl = 'api/users/getaccesstoken';

export const subscriptionsCompletedEvent = 'SubscriptionsCompleted';
export const accessTokenSetEvent = 'AccessTokenSet';
export const beginHttpEvent = 'BeginHttp';
export const completeHttpEvent = 'CompleteHttp';
export const beginLoginEvent = 'BeginLogin';
export const completeLoginEvent = 'CompleteLogin';

export const emitter = new TinyEmitter();

let _subscriptionsCompleted = false;
export const completeSubscriptions = () => {
    _subscriptionsCompleted = true;
    emitter.emit(subscriptionsCompletedEvent);
};

let _accessToken = '';
export const setAccessToken = (accessToken: string) => {
    _accessToken = accessToken;
    emitter.emit(accessTokenSetEvent, accessToken);
};

export const httpGet = <T>(url: string) => {
    return httpFetch<T>(url);
};

export const httpPost = <T>(url: string, object: any) => {
    return httpFetch<T>(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: object && JSON.stringify(object)
    });
};

const httpFetch = async <T>(url: string, requestInit?: RequestInit): Promise<T> => {
    if (!_subscriptionsCompleted) {
        await new Promise(resolve => emitter.once(subscriptionsCompletedEvent, resolve));
    }

    emitter.emit(beginHttpEvent);
    const response = await fetch(url, {
        ...requestInit,
        headers: {
            ...requestInit?.headers,
            ..._accessToken && { 'Authorization': `Bearer ${_accessToken}` }
        }
    }).finally(() => emitter.emit(completeHttpEvent));

    if (response.status === unauthorizedStatusCode && !url.startsWith(usersApiUrl)) {
        setAccessToken('');
        emitter.emit(beginLoginEvent);
        if (await new Promise(resolve => emitter.once(completeLoginEvent, resolve))) {
            return await httpFetch(url, requestInit);
        }
    }

    if (response.ok && url.startsWith(loginApiUrl)) {
        setAccessToken(await response.clone().json());
        emitter.emit(completeLoginEvent, true);
    }
    
    if (response.ok) {
        const text = await response.text();
        return text ? JSON.parse(text) : undefined;
    } else {
        throw {
            url: response.url,
            status: response.status,
            statusText: response.statusText
        };
    }
};
