import { AppThunkAction } from "../"
import { StockProduct } from "../../models/products/stock-product"
import { httpGet } from "../../services/http";

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';

const apiUrl = 'api/products';

interface LoadProductsAction {
    type: typeof LOAD_PRODUCTS;
}

interface LoadProductsSuccessAction {
    type: typeof LOAD_PRODUCTS_SUCCESS;
    products: StockProduct[];
}

export type ProductsActions =
    LoadProductsAction |
    LoadProductsSuccessAction;

export const loadProducts = (): AppThunkAction<ProductsActions, LoadProductsSuccessAction> => async (dispatch) => {
    const products = await httpGet<StockProduct[]>(apiUrl);
    return dispatch(loadProductsSuccess(products));
};

export const loadProductsSuccess = (products: StockProduct[]): ProductsActions => ({
    type: LOAD_PRODUCTS_SUCCESS,
    products: products
});
