import { Sale } from '../models/sales/sale';
import { httpGet, httpPost } from './http';

const apiUrl = 'api/sales';

export const getSale = (id: number) => httpGet<Sale>(`${apiUrl}/${id}`);
export const saveSale = (sale: Sale) => httpPost<Sale>(`${apiUrl}/savesale`, sale);
export const saveQuotation = (sale: Sale) => httpPost<Sale>(`${apiUrl}/savequotation`, sale);
export const cancelSale = (sale: Sale) => httpPost<Sale>(`${apiUrl}/cancelsale`, { id: sale.id });
export const saveSales = (sales: Sale[]) => httpPost<number[]>(`${apiUrl}/savesales`, sales);
