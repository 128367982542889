import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import SalePage from './components/sales/SalePage';
import SalesPage from './components/sales/SalesPage';
import StockEventPage from './components/stock/StockEventPage';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/venta/:id?' component={SalePage} />
        <Route path='/ventas' component={SalesPage} />
        <Route path='/inventario' component={StockEventPage} />
        {/* <Route path='/counter' component={Counter} />
        <Route path='/fetch-data/:startDateIndex?' component={FetchData} /> */}
    </Layout>
);
