export const productsLocalKey = 'products';
export const clientsLocalKey = 'clients';
export const businessUnitsLocalKey = 'businessUnits';
export const paymentMethodsLocalKey = 'paymentMethods';
export const recentSalesLocalKey = 'recentSales';
export const pendingSalesLocalKey = 'pendingSales';

export const getLocalItems = <T>(key: string): T[] | undefined => {
    var json = localStorage.getItem(key);
    return json ? JSON.parse(json) as T[] : undefined;
};

export const getLocalItemsOrEmpty = <T>(key: string): T[] => {
    return getLocalItems(key) ?? [];
};

export const setLocalItems = <T>(key: string, items: T[], sort?: (a: T, b: T) => number): void => {
    localStorage.setItem(key, JSON.stringify(sort ? items.sort(sort) : items));
};

export const addLocalItem = <T>(key: string, item: T, sort?: (a: T, b: T) => number): void => {
    const newList = [...getLocalItemsOrEmpty<T>(key), item];
    setLocalItems(key, sort ? newList.sort(sort) : newList);
};

export const replaceLocalItem = <T>(key: string, item: T, find: (i: T) => boolean, sort?: (a: T, b: T) => number): void => {
    const newList = getLocalItemsOrEmpty<T>(key).map(i => find(i) ? item : i);
    setLocalItems(key, sort ? newList.sort(sort) : newList);
};
