import { useState } from 'react';
import { FaBan, FaTimesCircle } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Sale } from "../../models/sales/sale"

interface SaleCancelButtonProps {
    sale: Sale;
    handleCancel: () => void;
}

const SaleCancelButton = ({ sale, handleCancel }: SaleCancelButtonProps) => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
    
    const handleClick = () => {
        setIsConfirmationOpen(true);
    };

    const handleToggle = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirm = () => {
        handleToggle();
        handleCancel();
    };

    if (sale.id !== 0){
        return (
            <>
                <Button block type="button" color="danger" disabled={sale.canceled} onClick={handleClick}><FaBan /> Cancelar Venta</Button>
                <Modal isOpen={isConfirmationOpen} toggle={handleToggle}>
                    <ModalHeader className="bg-danger text-white" toggle={handleToggle}>Cancelar Venta</ModalHeader>
                    <ModalBody>¿Estás seguro que deseas cancelar esta venta?</ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={handleToggle}><FaTimesCircle /> Cerrar</Button>
                        <Button color="danger" onClick={handleConfirm}><FaBan /> Cancelar Venta</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    } else {
        return <></>;
    }
};

export default SaleCancelButton;
