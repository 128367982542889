import { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Sale } from "../../models/sales/sale";
import { localDateDateString, localDateTimeString } from "../../services/dates";

interface SaleReceiptProps {
    sale?: Sale;
    resetSale: () => void;
}

const SaleReceipt = ({ sale, resetSale }: SaleReceiptProps) => {
    const maxLenght = 34;
    const productPadding = 7;
    const lineBreak = '%0A';

    const cashPaymentMethodId = 1;

    const [modalReceipt, setModalReceipt] = useState<string>('');

    useEffect(() => {
        if (sale) {
            let printString = '';
            printString += breakText('');
            printString += breakText('Av. Eje 8 212', 'center');
            printString += breakText('Col. Santa María Magdalena', 'center');
            printString += breakText('55715, Coacalco, Edo. de México', 'center');
            printString += breakText('RFC: EAMG730819418', 'center');
            printString += breakText('');
            printString += breakText('Tel: 55 6586 4238', 'center');
            printString += breakText('WhatsApp: 56 1151 6908', 'center');
            printString += breakText('');
            printString += breakText(`Fecha: ${localDateDateString(sale.date)}`, 'center');
            printString += breakText(`Hora: ${localDateTimeString(sale.date)}`, 'center');
            printString += breakText('');
            sale.saleItems.forEach(si => {
                printString += breakText(si.fullName, 'left', maxLenght - productPadding);
                printString += breakText(`${formatNumber(si.salePrice - si.saleDiscount, 6)} ${si.saleDiscount > 0 ? `(${formatNumber(si.salePrice, 6)} - ${formatNumber(si.saleDiscount / si.salePrice * 100, 4, 'percentage')})` : ''.padStart(15)} ${formatNumber(si.quantity, 3, 'quantity')} ${formatNumber((si.salePrice - si.saleDiscount) * si.quantity, 7)}`, 'right');
            });
            printString += breakText('');
            printString += breakText(`Total (IVA incluido): ${formatNumber(sale.saleItems.reduce((st, si) => st + (si.salePrice - si.saleDiscount) * si.quantity, 0), 8)}`, 'right');
            printString += breakText('');
            if (sale.quotation) {
                printString += breakText('Vigencia de la cotización: 15 días');
                printString += breakText('');
                printString += breakText('*Esta cotización no representa una venta.');
            } else {
                if (sale.salePaymentMethods.length) {
                    if (sale.salePaymentMethods.length > 1) {
                        printString += breakText(`Formas de pago:`);
                        sale.salePaymentMethods.forEach(spm => printString += breakText(`${spm.paymentMethodName}: ${formatNumber(spm.paymentAmount, 8)}`, 'right'));
                    } else {
                        printString += breakText(`Forma de pago: ${sale.salePaymentMethods[0].paymentMethodName}`);
                    }
                    const cashSalePaymentMethod = sale.salePaymentMethods.find(spm => spm.paymentMethodId === cashPaymentMethodId);
                    if (cashSalePaymentMethod) {
                        printString += breakText('');
                        printString += breakText(`Pago en efectivo: ${formatNumber(cashSalePaymentMethod.receivedAmount, 8)}`, 'right');
                        printString += breakText(`Cambio: ${formatNumber(cashSalePaymentMethod.receivedAmount - cashSalePaymentMethod.paymentAmount, 8)}`, 'right');
                    }
                    printString += breakText('');
                }
                printString += breakText('*Este recibo no es válido como comprobante fiscal.');
            }
            printString += breakText('');
            if (sale.quotation) {
                printString += breakText('¡GRACIAS POR SU VISITA!', 'center');
            } else if (sale.canceled) {
                printString += breakText('¡VENTA CANCELADA!', 'center');
            } else {
                printString += breakText('¡GRACIAS POR SU COMPRA!', 'center');
            }
            printString += breakText('');
            printString += breakText('');

            if (process.env.NODE_ENV === 'production') {
                location.href = `intent:${printString}#Intent;scheme=rawbt;package=ru.a402d.rawbtprinter;end;`;
            } else {
                setModalReceipt(printString.replace(new RegExp(lineBreak, 'g'), '\n'));
            }
        }
        resetSale();
    }, [sale]);

    const breakText = (s: string, align: 'left' | 'center' | 'right' = 'left', length: number = maxLenght) => {
        const regExp = new RegExp(`.{1,${length}}( |$)`, 'g');
        return `${s.match(regExp)?.map(l => alignText(l.trim(), align, length)).join(lineBreak) ?? ''}${lineBreak}`;
    }

    const alignText = (s:string, align: 'left' | 'center' | 'right' = 'left', length: number = maxLenght) => {
        switch(align) {
            case 'left':
                return s.padEnd(length);
            case 'center':
                return s.padStart((length + s.length) / 2).padEnd(length);
            case 'right':
                return s.padStart(length);
        }
    };

    const formatNumber = (n: number, length: number, type: 'currency' | 'percentage' | 'quantity' = 'currency') => {
        const prefix = type === 'currency' ? '$' : type === 'quantity' ? '×' : ''
        const suffix = type === 'percentage' ? '%' : '';
        return `${prefix}${Math.round(n).toLocaleString()}${suffix}`.padStart(length);
    };

    return (
        <Modal isOpen={modalReceipt != ''} toggle={() => setModalReceipt('')}>
            <ModalHeader className="bg-warning" toggle={() => setModalReceipt('')}>
                Imprimir Recibo
            </ModalHeader>
            <ModalBody>
                <div className="text-center">
                    <pre className="d-inline-block text-left">{modalReceipt}</pre>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default SaleReceipt;
