import { useEffect, useState } from "react";
import { Button, InputGroup, InputGroupAddon } from "reactstrap";
import { AllTypeaheadOwnAndInjectedProps, Typeahead, TypeaheadMenuProps } from "react-bootstrap-typeahead";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { StockProduct } from "../../models/products/stock-product";
import 'react-bootstrap-typeahead/css/Typeahead.css';

//avoid calc filter words for every item

interface ProductSelectorProps {
    products: StockProduct[];
    disabled?: boolean;
    onProductSelected: (id: number) => void;
    onBarcodeScanned?: (barcode: string) => void;
}

const ProductSelector = ({
    products,
    disabled,
    onProductSelected,
    onBarcodeScanned
}: ProductSelectorProps) => {
    const [fullSearch, setFullSearch] = useState<boolean>(false);

    useEffect(() => {
        if (onBarcodeScanned) {
            window.addEventListener('keydown', handleKeyDown);
            return () => window.removeEventListener('keydown', handleKeyDown);
        }
    }, [products]);
    
    let keyBuffer = '';
    let scanning = false;
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === '~' || event.key === 'º' || event.key === '°') {
            scanning = true;
        }

        if (scanning) {
            if (event.key >= '0' && event.key <= '9') {
                keyBuffer += event.key;
            }

            if (event.key === 'Enter') {
                onBarcodeScanned!(keyBuffer);
                keyBuffer = '';
                scanning = false;
            }

            event.preventDefault();
        }
    };

    const filterBy = (product: StockProduct, { text }: AllTypeaheadOwnAndInjectedProps<StockProduct>) => {
        if (product.visible || fullSearch) {
            const filterWords = text.split(' ').filter(w => w).map(w => normalize(w)); //Avoid calc for every item
            if (filterWords.length) {
                const productFullName = normalize(product.fullName);
                return filterWords.every(w => productFullName.includes(w));
            }
        }      
        return false;
    };

    const renderMenuItemChildren = (product: StockProduct, { text }: TypeaheadMenuProps<StockProduct>) => {
        const filterWords = (text ?? '').split(' ').filter(w => w).map(w => normalize(w)); //Avoid calc for every item
        if (filterWords.length) {
            const productFullName = normalize(product.fullName);
            const boldPositions = new Array(productFullName.length).fill(false);
            filterWords.forEach(word => {
                let index = 0;
                let subIndex = -1;
                while (index < productFullName.length && (subIndex = productFullName.substring(index).indexOf(word)) !== -1) {
                    index += subIndex;
                    boldPositions.fill(true, index, index + word.length);
                    index++;
                }
            });
            
            let startIndex = 0;
            let childrenArray = [];
            for (let i = 1; i <= boldPositions.length; i++) {
                if (i === boldPositions.length || boldPositions[i] !== boldPositions[i - 1]) {
                    if (boldPositions[startIndex]) {
                        childrenArray.push(<mark key={i} className="rbt-highlight-text">{product.fullName.substring(startIndex, i)}</mark>);
                    } else {
                        childrenArray.push(product.fullName.substring(startIndex, i));
                    }
                    startIndex = i;
                }
            }
            
            return <div className="py-1 text-truncate">{childrenArray}</div>;
        }

        return <div className="py-1 text-truncate">{product.fullName}</div>;
    };

    const normalize = (s: string) => s.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return (
        <InputGroup>
            <Typeahead
                id="product-selector"
                labelKey="fullName"
                placeholder="Buscar producto"
                emptyLabel="No se encontraron productos."
                paginationText="Mostrar más productos..."
                minLength={2}
                clearButton={true}
                options={products}
                disabled={disabled}
                filterBy={filterBy}
                renderMenuItemChildren={renderMenuItemChildren}
                onChange={(products) => onProductSelected(products[0]?.id)}
            />
            <InputGroupAddon addonType="append">
                <Button color="info" active={fullSearch} disabled={disabled} onClick={() => setFullSearch(!fullSearch)}>
                    {fullSearch ? <FaEyeSlash /> : <FaEye />}
                </Button>
            </InputGroupAddon>
        </InputGroup>
    );
};

export default ProductSelector;
