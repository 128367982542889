import { AppThunkAction } from "..";
import { Client } from "../../models/sales/client";
import { BusinessUnit } from "../../models/sales/business-unit";
import { PaymentMethod } from "../../models/sales/payment-method";
import { Sale } from "../../models/sales/sale";
import { httpGet } from "../../services/http";

export const LOAD_CLIENTS = 'LOAD_CLIENTS';
export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_BUSINESS_UNITS = 'LOAD_BUSINESS_UNITS';
export const LOAD_BUSINESS_UNITS_SUCCESS = 'LOAD_BUSINESS_UNITS_SUCCESS';
export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
export const LOAD_PAYMENT_METHODS_SUCCESS = 'LOAD_PAYMENT_METHODS_SUCCESS';
export const LOAD_RECENT_SALES = 'LOAD_RECENT_SALES';
export const LOAD_RECENT_SALES_SUCCESS = 'LOAD_RECENT_SALES_SUCCESS';

const clientsApiUrl = 'api/clients';
const businessUnitsApiUrl = 'api/businessunits';
const paymentMethodsApiUrl = 'api/paymentmethods';
const salesApiUrl = 'api/sales';

interface LoadClientsAction {
    type: typeof LOAD_CLIENTS;
}

interface LoadClientsSuccessAction {
    type: typeof LOAD_CLIENTS_SUCCESS;
    clients: Client[];
}

interface LoadBusinessUnitsAction {
    type: typeof LOAD_BUSINESS_UNITS;
}

interface LoadBusinessUnitsSuccessAction {
    type: typeof LOAD_BUSINESS_UNITS_SUCCESS;
    businessUnits: BusinessUnit[];
}

interface LoadPaymentMethodsAction {
    type: typeof LOAD_PAYMENT_METHODS;
}

interface LoadPaymentMethodsSuccessAction {
    type: typeof LOAD_PAYMENT_METHODS_SUCCESS;
    paymentMethods: PaymentMethod[];
}

interface LoadRecentSalesAction {
    type: typeof LOAD_RECENT_SALES;
}

interface LoadRecentSalesSuccessAction {
    type: typeof LOAD_RECENT_SALES_SUCCESS;
    recentSales: Sale[];
}

export type SalesActions =
    LoadClientsAction |
    LoadClientsSuccessAction |
    LoadBusinessUnitsAction |
    LoadBusinessUnitsSuccessAction |
    LoadPaymentMethodsAction |
    LoadPaymentMethodsSuccessAction |
    LoadRecentSalesAction |
    LoadRecentSalesSuccessAction;

export const loadClients = (): AppThunkAction<SalesActions, LoadClientsSuccessAction> => async (dispatch) => {
    const clients = await httpGet<Client[]>(clientsApiUrl);
    return dispatch(loadClientsSuccess(clients));
};

export const loadClientsSuccess = (clients: Client[]): SalesActions => ({
    type: LOAD_CLIENTS_SUCCESS,
    clients: clients
});

export const loadBusinessUnits = (): AppThunkAction<SalesActions, LoadBusinessUnitsSuccessAction> => async (dispatch) => {
    const businessUnits = await httpGet<BusinessUnit[]>(businessUnitsApiUrl);
    return dispatch(loadBusinessUnitsSuccess(businessUnits));
};

export const loadBusinessUnitsSuccess = (businessUnits: BusinessUnit[]): SalesActions => ({
    type: LOAD_BUSINESS_UNITS_SUCCESS,
    businessUnits: businessUnits
});

export const loadPaymentMethods = (): AppThunkAction<SalesActions, LoadPaymentMethodsSuccessAction> => async (dispatch) => {
    const paymentMethods = await httpGet<PaymentMethod[]>(paymentMethodsApiUrl);
    return dispatch(loadPaymentMethodsSuccess(paymentMethods));
};

export const loadPaymentMethodsSuccess = (paymentMethods: PaymentMethod[]): SalesActions => ({
    type: LOAD_PAYMENT_METHODS_SUCCESS,
    paymentMethods: paymentMethods
});

export const loadRecentSales = (): AppThunkAction<SalesActions, LoadRecentSalesSuccessAction> => async (dispatch) => {
    const recentSales = await httpGet<Sale[]>(salesApiUrl);
    return dispatch(loadRecentSalesSuccess(recentSales));
};

export const loadRecentSalesSuccess = (recentSales: Sale[]): SalesActions => ({
    type: LOAD_RECENT_SALES_SUCCESS,
    recentSales: recentSales
});
