import { ChangeEvent } from "react";
import ReactNumberFormat, { NumberFormatValues } from "react-number-format";

interface NumberFormatProps {
    type: 'text' | 'plain' | 'read' | 'write';
    format: 'currency' | 'percentage' | 'integer' | 'fraction';
    value: number;
    bold?: boolean,
    scale?: number;
    name?: string;
    min?: number;
    max?: number;
    suffix?: string;
    negative?: boolean;
    invalid?: boolean;
    onChange?: (name: string, value: number) => void;
    getInputRef?: (el: HTMLInputElement) => void;
}

const NumberFormat = ({type, format, value, bold, scale, name, min, max, suffix, negative, invalid, onChange, getInputRef}: NumberFormatProps) => {
    
    const displayType = type === 'read' || type === 'plain' || type === 'write' ? 'input' : 'text';
    const formClassName = type === 'plain' ? 'form-control-plaintext' : type === 'read' || type === 'write' ? 'form-control' : undefined;
    const alignClassName = type === 'read' || type === 'plain' || type === 'write' ? 'text-center' : undefined;
    const fontClassName = bold ? 'font-weight-bold' : undefined;
    const invalidClassName = invalid ? 'is-invalid' : undefined;
    const readOnly = type === 'plain' || type === 'read';
    const prefix = format === 'currency' ? '$' : undefined;
    const suffixValue = suffix !== undefined ? suffix : format === 'percentage' ? '%' : undefined;
    const thousandSeparator = format === 'currency' ? ',' : undefined;
    const decimalScale = scale ?? (format === 'currency' || format === 'percentage' ? 2 : format === 'fraction' ? 3 : undefined);
    const fixedDecimalScale = format === 'currency' || format === 'percentage';
    const allowNegative = negative !== undefined ? negative : false;

    const formatValue = format === 'fraction' ? (value: string) => {
        const integer = Math.trunc(+value);
        const fraction = Math.abs(+value % 1).toFixed(3);

        let unicodeFraction = '';
        if (fraction == '0.125') unicodeFraction = '⅛';
        if (fraction == '0.250') unicodeFraction = '¼';
        if (fraction == '0.375') unicodeFraction = '⅜';
        if (fraction == '0.500') unicodeFraction = '½';
        if (fraction == '0.625') unicodeFraction = '⅝';
        if (fraction == '0.750') unicodeFraction = '¾';
        if (fraction == '0.875') unicodeFraction = '⅞';

        return `${integer || !unicodeFraction ? integer : ''}${unicodeFraction}${suffix}`;
    } : undefined;

    const removeFormatting = format === 'fraction' ? (formattedValue: string) => {
        const value = formattedValue.replace(suffix!, '');
        const integer = value.replace(/[⅛¼⅜½⅝¾⅞]/, '');
        const unicodeFraction = value.replace(integer, '');

        let fraction = '';
        if (unicodeFraction == '⅛') fraction = '125';
        if (unicodeFraction == '¼') fraction = '25';
        if (unicodeFraction == '⅜') fraction = '375';
        if (unicodeFraction == '½') fraction = '5';
        if (unicodeFraction == '⅝') fraction = '625';
        if (unicodeFraction == '¾') fraction = '75';
        if (unicodeFraction == '⅞') fraction = '875';

        if (fraction)
            return `${integer ? integer : '0'}.${fraction}`;
        else
            return integer;
    } : undefined;

    const isAllowed = min !== undefined || max !== undefined ? ({ floatValue }: NumberFormatValues) => {
        return floatValue! >= (min ?? floatValue!) && floatValue! <= (max ?? floatValue!);
    } : undefined;

    const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            const unformattedValue = removeFormatting ? removeFormatting(value) : value;
            onChange(name, +unformattedValue.replace(/\$|%|,/g, ''));
        }
    };

    const handleFocus = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (type === 'write') {
            target.select();
        }
    };
    
    return (
        <ReactNumberFormat
            displayType={displayType}
            className={`${formClassName} ${alignClassName} ${fontClassName} ${invalidClassName}`}
            readOnly={readOnly}
            prefix={prefix}
            suffix={suffixValue}
            thousandSeparator={thousandSeparator}
            decimalScale={decimalScale}
            fixedDecimalScale={fixedDecimalScale}
            allowNegative={allowNegative}
            format={formatValue}
            removeFormatting={removeFormatting}
            name={name}
            value={value}
            isAllowed={isAllowed}
            onChange={handleChange}
            onBlur={handleChange}
            onFocus={handleFocus}
            getInputRef={getInputRef}
            autoComplete="off"
        />
    );
}

export default NumberFormat;
