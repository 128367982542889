import { LOAD_PRODUCTS_SUCCESS, ProductsActions } from "./actions";
import { initialState, ProductsState } from "./state";

export const productsReducer = (state: ProductsState = initialState, action: ProductsActions): ProductsState => {
    switch(action.type) {
        case LOAD_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.products
            };
        default:
            return state;
    }
}
