import { Button, Col, CustomInput, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { SaleItemComponent } from '../../models/sales/sale-item-component';
import NumberFormat from '../common/NumberFormat';

interface SaleItemComponentFormProps {
    saleItemComponent: SaleItemComponent;
    minUnitsNumber: number;
    maxUnitsNumber: number;
    invalid: boolean;
    idSuffix: string;
    onChange: (name: string, value: any) => void;
    onRemove: () => void;
}

const SaleItemComponentForm = ({ saleItemComponent, minUnitsNumber, maxUnitsNumber, invalid, idSuffix, onChange, onRemove }: SaleItemComponentFormProps) => {
    return (
        <>
            <hr className="my-2" />
            {saleItemComponent.id === 0 && <Button close className="text-danger mt-n1" onClick={onRemove} />}
            <h6>{saleItemComponent.fullName}</h6>
            <Row form>
                <Col xs="10">
                    <FormGroup className="mb-0">
                        <Label>Unidades</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color={invalid ? 'danger' : 'info'} disabled={saleItemComponent.id !== 0 || saleItemComponent.unitsNumber < Math.max(0, minUnitsNumber) + 1} onClick={() => onChange('unitsNumber', saleItemComponent.unitsNumber - 1)}>-1</Button>
                                <Button color={invalid ? 'danger' : 'info'} disabled={saleItemComponent.id !== 0 || saleItemComponent.unitsNumber <= Math.max(0, minUnitsNumber)} onClick={() => onChange('unitsNumber', saleItemComponent.unitsNumber - 0.125)}>-⅛</Button>
                            </InputGroupAddon>
                            <NumberFormat type="read" format="fraction" name="unitsNumber" value={saleItemComponent.unitsNumber} suffix={saleItemComponent.unitAbbreviation} invalid={invalid} onChange={onChange} />
                            <InputGroupAddon addonType="append">
                                <Button color={invalid ? 'danger' : 'info'} disabled={saleItemComponent.id !== 0 || saleItemComponent.unitsNumber >= Math.min(saleItemComponent.maxUnitsNumber, maxUnitsNumber)} onClick={() => onChange('unitsNumber', saleItemComponent.unitsNumber + 0.125)}>+⅛</Button>
                                <Button color={invalid ? 'danger' : 'info'} disabled={saleItemComponent.id !== 0 || saleItemComponent.unitsNumber > Math.min(saleItemComponent.maxUnitsNumber, maxUnitsNumber) - 1} onClick={() => onChange('unitsNumber', saleItemComponent.unitsNumber + 1)}>+1</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs="1">
                    <div className="ml-n4">
                        <div className="ml-n5">
                            <div className="ml-n5">
                                <FormGroup className="ml-n5">
                                    <CustomInput type="checkbox" id={`started-${idSuffix}`} label="Iniciado" checked={saleItemComponent.started} disabled={saleItemComponent.id !== 0} onChange={(e) => onChange('started', e.target.checked)} />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs="1">
                    <div className="ml-n5">
                        <FormGroup className="ml-n5">
                            <CustomInput type="checkbox" id={`finished-${idSuffix}`} label="Terminado" checked={saleItemComponent.finished} disabled={saleItemComponent.id !== 0} onChange={(e) => onChange('finished', e.target.checked)} />
                        </FormGroup>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default SaleItemComponentForm;
