import { useEffect, useState } from 'react';
import { Button, Col, CustomInput, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { SaleItem } from '../../models/sales/sale-item';
import { SaleItemComponent } from '../../models/sales/sale-item-component';
import NumberFormat from '../common/NumberFormat';
import SaleItemComponentForm from './SaleItemComponentForm';

interface SaleItemFormProps {
    saleItem: SaleItem;
    advacedEdition: boolean;
    idSuffix: string;
    onChange: (name: string, value: any) => void;
}

const SaleItemForm = ({ saleItem, advacedEdition, idSuffix, onChange }: SaleItemFormProps) => {
    const [discountType, setDiscountType] = useState('$');
    const [percentageDiscount, setPercentageDiscount] = useState(0);
    const [totalUnitPrice, setTotalUnitPrice] = useState(0);
    const [totalItemPrice, setTotalItemPrice] = useState(0);
    
    let percentageDiscountInput: HTMLInputElement;
    let totalUnitPriceInput: HTMLInputElement;

    useEffect(() => {
        if (percentageDiscountInput != document.activeElement) {
            setPercentageDiscount(saleItem.saleDiscount / saleItem.salePrice * 100);
        }
        if (totalUnitPriceInput != document.activeElement) {
            setTotalUnitPrice(saleItem.salePrice - saleItem.saleDiscount);
        }
    }, [saleItem, saleItem.saleDiscount]);

    useEffect(() => {
        setTotalItemPrice((saleItem.salePrice - saleItem.saleDiscount) * saleItem.quantity);
    }, [saleItem, saleItem.saleDiscount, saleItem.quantity]);

    useEffect(() => {
        onChange('saleItemComponents', getUpdatedSaleItemComponents(saleItem.saleItemComponents, saleItem.measureUnitsNumber * saleItem.quantity, 0, -1));
    }, [saleItem.quantity, saleItem.measureUnitsNumber]);

    const handleChange = (name: string, value: number) => {
        switch (name) {
            case 'percentageDiscount':
                setPercentageDiscount(value);
                return onChange('saleDiscount', roundToZero(saleItem.salePrice * value / 100));
            case 'totalUnitPrice':
                setTotalUnitPrice(value);
                return onChange('saleDiscount', roundToZero(saleItem.salePrice - value));
            default:
                onChange(name, value);
        }
    }

    const updateSaleItemComponent = (index: number, name: string, value: any) => {
        onChange('saleItemComponents', getUpdatedSaleItemComponents(saleItem.saleItemComponents.map((sic, i) => i !== index ? sic : {
            ...sic,
            [name]: value
        }), saleItem.measureUnitsNumber * saleItem.quantity, index + 1, index));
    };

    const removeSaleItemComponent = (index: number) => {
        onChange('saleItemComponents', getUpdatedSaleItemComponents(saleItem.saleItemComponents.filter((_, i) => i !== index), saleItem.measureUnitsNumber * saleItem.quantity, index, -1));
    };

    const getUpdatedSaleItemComponents = (saleItemComponents: SaleItemComponent[], unitsTotal: number, beginingIndex: number, updatingIndex: number) => {
        return saleItemComponents.reduce((sics, _, i) => {
            const currentIndex = (i + beginingIndex) % sics.length;
            return sics.map((sic, i) => i === currentIndex && i !== updatingIndex ? {
                ...sic,
                unitsNumber: roundToFraction(Math.min(Math.max(unitsTotal - sics.filter((_, i) => i !== currentIndex).reduce((un, sic) => un + sic.unitsNumber, 0), 0), sic.maxUnitsNumber))
            } : sic);
        }, saleItemComponents);
    };

    const roundToZero = (value: number) => Math.abs(value) % 1 <= 0.5 ? Math.trunc(value) : Math.round(value);
    const roundToFraction = (value: number) => +`${Math.round(+`${value}e+3`)}e-3`;

    return (
        <Form>
            <h6>Precio unitario: <NumberFormat type="text" format="currency" value={saleItem.salePrice} /></h6>
            <Row form>
                <Col xs="7">
                    <FormGroup>
                        <Label>Descuento</Label>
                        {saleItem.id !== 0 || advacedEdition ? (
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <Button color="info" active={discountType === '$'} disabled={saleItem.id !== 0} onClick={() => setDiscountType('$')}>$</Button>
                                    <Button color="info" active={discountType === '%'} disabled={saleItem.id !== 0} onClick={() => setDiscountType('%')}>%</Button>
                                </InputGroupAddon>
                                {discountType === '$' && <NumberFormat type={saleItem.id !== 0 ? 'read' : 'write'} format="currency" name="saleDiscount" value={saleItem.saleDiscount} max={saleItem.salePrice} negative={true} onChange={handleChange} />}
                                {discountType === '%' && <NumberFormat type={saleItem.id !== 0 ? 'read' : 'write'} format="percentage" name="percentageDiscount" value={percentageDiscount} max={100} onChange={handleChange} negative={true} getInputRef={el => percentageDiscountInput = el} />}
                            </InputGroup>
                        ) : (
                            <div className="pt-2 mr-n3">
                                <CustomInput inline type="radio" id={`discount-${idSuffix}-0`} label="0%" name={`discount-${idSuffix}`} checked={Math.round(percentageDiscount) === 0} onChange={() => handleChange('percentageDiscount', 0)} />
                                <CustomInput inline type="radio" id={`discount-${idSuffix}-5`} label="5%" name={`discount-${idSuffix}`} checked={Math.round(percentageDiscount) === 5} onChange={() => handleChange('percentageDiscount', 5)} />
                                <CustomInput inline type="radio" id={`discount-${idSuffix}-10`} label="10%" name={`discount-${idSuffix}`} checked={Math.round(percentageDiscount) === 10} onChange={() => handleChange('percentageDiscount', 10)} />
                            </div>
                        )}
                    </FormGroup>
                </Col>
                <Col xs="5">
                    <FormGroup>
                        <Label>Subtotal</Label>
                        <NumberFormat type={saleItem.id !== 0 ? 'read' : advacedEdition ? 'write' : 'plain'} format="currency" name="totalUnitPrice" value={totalUnitPrice} onChange={handleChange} getInputRef={el => totalUnitPriceInput = el} />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col xs="6">
                    <FormGroup className="mb-0">
                        <Label>Cantidad</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="info" disabled={saleItem.id !== 0 || saleItem.quantity <= 1} onClick={() => handleChange('quantity', saleItem.quantity - 1)}>-1</Button>
                            </InputGroupAddon>
                            <NumberFormat type={saleItem.id !== 0 ? 'read' : 'write'} format="integer" name="quantity" value={saleItem.quantity} min={1} onChange={handleChange} />
                            <InputGroupAddon addonType="append">
                                <Button color="info" disabled={saleItem.id !== 0} onClick={() => handleChange('quantity', saleItem.quantity + 1)}>+1</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs="1"></Col>
                <Col xs="5">
                    <FormGroup className="mb-0">
                        <Label>Total</Label>
                        <NumberFormat type="plain" format="currency" value={totalItemPrice} bold={true} />
                    </FormGroup>
                </Col>
            </Row>
            <Row form>
                <Col>
                    {saleItem.saleItemComponents.length > 0 && (
                        <>
                            {saleItem.saleItemComponents.map((saleItemComponent, i) => (
                                <SaleItemComponentForm
                                    key={i}
                                    saleItemComponent={saleItemComponent}
                                    minUnitsNumber={saleItem.measureUnitsNumber * saleItem.quantity - saleItem.saleItemComponents.filter(sic => sic !== saleItemComponent).reduce((mun, sic) => mun + sic.maxUnitsNumber, 0)}
                                    maxUnitsNumber={saleItem.measureUnitsNumber * saleItem.quantity}
                                    invalid={saleItem.saleItemComponents.reduce((un, sic) => un + sic.unitsNumber, 0) < saleItem.measureUnitsNumber * saleItem.quantity}
                                    idSuffix={`${idSuffix}-${i}`}
                                    onChange={(n, v) => updateSaleItemComponent(i, n, v)}
                                    onRemove={() => removeSaleItemComponent(i)}
                                />
                            ))}
                        </>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default SaleItemForm;
