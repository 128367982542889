import { Col, Row } from 'reactstrap';
import { SaleItem } from '../../models/sales/sale-item'
import NumberFormat from '../common/NumberFormat';

interface SaleItemSummaryProps {
    saleItem: SaleItem;
}

const SaleItemSummary = ({ saleItem }: SaleItemSummaryProps) => {
    return (
        <>
            <h6>{saleItem.fullName}</h6>
            <Row className="mx-n1">
                <Col xs="2" className="px-1 text-right">
                    <div className="pr-2">
                        <NumberFormat type="text" format="integer" value={saleItem.saleItemComponents.length} />CI
                    </div>
                </Col>
                <Col xs="2" className="px-1 text-right">
                    <div className="ml-n2 pr-2">
                        <NumberFormat type="text" format="percentage" scale={0} value={saleItem.saleDiscount / saleItem.salePrice * 100} />
                    </div>
                </Col>
                <Col xs="3" className="px-1 text-right">
                    <div className="ml-n2 pr-2">
                        <NumberFormat type="text" format="currency" value={saleItem.salePrice - saleItem.saleDiscount} />
                    </div>
                </Col>
                <Col xs="1" className="px-1 text-right">
                    <div className="ml-n2 mr-n2">
                        &times;<NumberFormat type="text" format="integer" value={saleItem.quantity} />
                    </div>
                </Col>
                <Col xs="4" className="px-1 text-right">
                    <b className="pl-2">
                        <NumberFormat type="text" format="currency" value={(saleItem.salePrice - saleItem.saleDiscount) * saleItem.quantity} />
                    </b>
                </Col>
            </Row>
        </>
    );
};

export default SaleItemSummary;
