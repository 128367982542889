import { toast } from 'react-toastify';

export const useToastMessage = () => {
    const showInfoMessage = (message: string) => toast.info(message);
    const showSuccessMessage = (message: string) => toast.success(message);
    const showWarningMessage = (message: string) => toast.warning(message);
    const showErrorMessage = (message: string) => toast.error(message);

    return { showInfoMessage, showSuccessMessage, showWarningMessage, showErrorMessage };
};
