import { useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem, Button, Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import './NavMenu.css';
import { FaUser } from 'react-icons/fa';

interface NavMenuProps {
    startLogin: () => void;
}

const NavMenu = ({ startLogin }: NavMenuProps) => {
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);
    const [path, setPath] = useState<string[]>([]);

    useEffect(() => {
        setPath(location.pathname === '/' ? ['Inicio'] : ['Inicio', ...location.pathname.split('/').slice(1)]);
        return history.listen(location => {
            setPath(location.pathname === '/' ? ['Inicio'] : ['Inicio', ...location.pathname.split('/').slice(1)]);
        });
    }, []);

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                <Container>
                    <Breadcrumb>
                        {path.map((p, i) => (
                            <BreadcrumbItem
                                key={i}
                                active={i === path.length - 1}
                                tag={i < path.length - 1 ? Link : 'span'}
                                to={`/${path.slice(1, i + 1).join('/')}`}
                            >{p}</BreadcrumbItem>
                        ))}
                    </Breadcrumb>
                    <Button outline type="button" className="ml-auto mr-2 mx-sm-0 order-sm-12" style={{ color: 'rgba(0, 0, 0, 0.5)', borderColor: 'rgba(0, 0, 0, 0.1)' }} onClick={startLogin}>
                        <FaUser />
                    </Button>
                    <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
                    <Collapse navbar className="d-sm-inline-flex flex-sm-row-reverse flex-sm-grow-0" isOpen={isOpen}>
                        <ul className="navbar-nav flex-grow" onClick={() => setIsOpen(false)}>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/">Inicio</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/venta">Ventas</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/inventario">Inventario</NavLink>
                            </NavItem>
                            {/* <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                            </NavItem> */}
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default NavMenu;
