import { useEffect, useState } from 'react';
import { FaSearch, FaSyncAlt, FaUpload } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { Sale } from "../../models/sales/sale";
import SaleFinder from "./SaleFinder";
import SaleListItem from "./SaleListItem";

interface SaleListProps {
    pendingSales: Sale[];
    recentSales: Sale[];
    onUploadSales: () => void;
    onUpdateSales: () => void;
    onFindSale: (id: number) => void;
    onSelectSale: (id: number) => void;
}

const SaleList = ({ pendingSales, recentSales, onUploadSales, onUpdateSales, onFindSale, onSelectSale }: SaleListProps) => {
    const circleStyle = {
        width: '8px',
        height: '8px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '50%',
        display: 'inline-block',
        marginBottom: '0.1rem'
    };

    const [isFinding, setIsFinding] = useState<boolean>(false);

    useEffect(() => {
        //Revisar si deben cargarse siempre o sólo cuando esté vacío
        onUpdateSales();
    }, []);

    const handleFindSaleClick = () => {
        setIsFinding(true);
    };

    const handleCloseFinder = () => {
        setIsFinding(false);
    };

    return (
        <>
            <Row className="mx-0">
                <Col xs="4" className="px-0">
                    <div className="border-success" style={circleStyle}></div> Venta
                </Col>
                <Col xs="4" className="px-0 ml-n3">
                    <div className="border-info" style={circleStyle}></div> Cotización
                </Col>
                <Col xs="4" className="px-0 ml-3">
                    <div className="border-danger" style={circleStyle}></div> Cancelación
                </Col>
            </Row>
            {pendingSales.length > 0 && (
                <>
                    <Row className="mx-0 mt-3">
                        <Col xs="8" className="px-0">
                            <h5>Ventas Pendientes</h5>
                        </Col>
                        <Col xs="4" className="px-0 text-right">
                            <Button type="button" color="warning" size="sm" onClick={onUploadSales}><FaUpload /></Button>
                        </Col>
                    </Row>
                    {pendingSales.map((s, i) => (
                        <SaleListItem
                            key={i}
                            sale={s}
                            onSelectSale={onSelectSale}
                        />
                    ))}
                </>
            )}
            <Row className="mx-0 mt-3">
                <Col xs="8" className="px-0">
                    <h5>Ventas Recientes</h5>
                </Col>
                <Col xs="4" className="px-0 text-right">
                    <Button type="button" color="info" size="sm" onClick={onUpdateSales}><FaSyncAlt /></Button>
                    <Button type="button" color="primary" size="sm" className="ml-2" onClick={handleFindSaleClick} ><FaSearch /></Button>
                    <SaleFinder
                        isFinding={isFinding}
                        onFindSale={onFindSale}
                        onCloseFinder={handleCloseFinder}
                    />
                </Col>
            </Row>
            {recentSales.map((s, i) => (
                <SaleListItem
                    key={i}
                    sale={s}
                    onSelectSale={onSelectSale}
                />
            ))}
        </>
    );
};

export default SaleList;
